import { Box, Flex, Select, Spacer, Stack, Text } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useParams,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosLable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getPrintersList } from "../pos-station/PosService";

const SystemAllGlobalSettingsCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { id } = useParams();
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  const [locations, setLocations] = useState([]);
  const [allValue, setSettings] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedPosName, setSelectedPosName] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [tipsArray, setTipsData] = useState(true);
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading1 = open && options.length === 0;
  const [autoSelectedPrinter, setAutoSelectedPrinter] = useState(null);
  // const [inputValueAuto, setInputValueAuto] = useState(null);
  const [largePrinterData, hideLargePrinterData] = useState(false);
  const [largePrinterName, setLargePrinterName] = useState(null);
  const [categoryName, setSelectedCategoryName] = useState(null);

  const sleep = (duration) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  };

  useEffect(() => {
    let active = true;

    if (!loading1) {
      return undefined;
    }

    (async () => {
      await sleep(450); // For demo purposes.
      if (active) {
        search();
      }
    })();

    return () => {
      active = false;
    };
  }, [loading1]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const search = () => {
    const printersAPIKey = allValue
      .map((fl) => {
        // Find the first matching object where vl.key is "large_printer_api_key" and vl.value is not null or empty
        const apiV = fl.category_data.find(
          (vl) =>
            vl.key === "large_printer_api_key" &&
            vl.value !== null &&
            vl.value !== ""
        );

        // If apiV exists, return its value; otherwise, return null
        return apiV ? apiV.value : null;
      })
      .filter((value) => value !== null);
    if (printersAPIKey.length > 0) {
      try {
        let data = {
          printer_api_key: printersAPIKey[0],
        };
        getPrintersList(data)
          .then((response) => {
            if (response.data.flag === true) {
              setOptions(response.data.data);
              myContext.handleLoading(false);
            } else {
              setOptions([]);
              setOpen(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setOptions([]);
            setOpen(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setOptions([]);
        setOpen(false);
        myContext.handleLoading(false);
      }
    } else {
      setOpen(false);
      setAutoSelectedPrinter(null);
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.POS_MODULE,
        alertDescription: "Large Printer API Key is required.",
      });
    }
  };

  const autoCompleteSelectedPrinter = (prin) => {
    if (undefined != prin && null != prin && "" != prin) {
      setAutoSelectedPrinter(prin);
      // setLargePrinterName(prin.name)
      let printerDetails = [];
      allValue.map((fl) => {
        fl.category_data.map((vl, ind) => {
          if (vl.key == "large_printer") {
            printerDetails.push({
              id: prin.id,
              name: prin.name,
            });
            vl.value = printerDetails;
          }
        });
      });
    } else {
      setAutoSelectedPrinter(null);
      // setLargePrinterName(null);
      setOptions([]);
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse?.data[Constants.FLAGE] &&
          true === loaderResponse?.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          const arraySettings = Object.keys(loaderResponse?.data?.data).map(
            (key) => ({
              category: key,
              headerName:
                loaderResponse?.data?.data[key][0].global_settings_category
                  .name,
              category_data: loaderResponse?.data?.data[key],
            })
          );
          setSelectedCategoryName(arraySettings[0].headerName);
          setSettings(arraySettings);
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }

    if (actionRun.current === true) {
      if (
        undefined != actionResponse &&
        null != actionResponse &&
        {} != actionResponse
      ) {
        myContext.handleLoading(false);
        setButtonDisable(false);
        if (
          undefined != actionResponse?.error &&
          null != actionResponse?.error &&
          actionResponse?.error
        ) {
          let actionData = actionResponse;
          error({ actionData });
        } else if (
          actionResponse.data[Constants.FLAGE] !== null &&
          actionResponse.data[Constants.FLAGE] === true
        ) {
          props.navigate(Constants.SYS_ALL_GLOBAL_SETTINGS, {
            state: paramData,
          });
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.MASTER_ADMIN_GLOBAL_MODULE,
            alertDescription: actionResponse.data.message,
          });
        } else {
          let actionData = actionResponse;
          error({ actionData });
          myContext.handleLoading(false);
        }
      }
      // return () => {
      actionRun.current = false;
      // };
    }
  }, [loaderResponse, actionResponse]);

  const validateField = (value, key, field) => {
    if (field.required == 1 && !value && key == field.key) {
      return `${field.setting_display_name} is required`;
    }
    if (
      field.input_type == 0 &&
      value &&
      value.length > 191 &&
      key == field.key
    ) {
      return `${field.setting_display_name} cannot exceed 191 characters`;
    }
    // if (
    //   field.input_type == 0 &&
    //   value &&
    //   (key == "kitchen_printer_ip" || key == "printer_ip")
    // ) {
    //   const re = new RegExp(Constants.IP_REGEX);
    //   const testResult = re.test(value.trim().toLowerCase());
    //   if (!testResult) {
    //     return `Please enter a ${field.setting_display_name}.`;
    //   }
    // }
    if (
      field.input_type == 1 &&
      value &&
      (key == "tip_perc_1" || key == "tip_perc_2" || key == "tip_perc_3")
    ) {
      let flag = true;
      let error = "";
      if ("tip_perc_1" in validationErrors) {
        validationErrors["tip_perc_1"] = "";
      }
      if ("tip_perc_2" in validationErrors) {
        validationErrors["tip_perc_2"] = "";
      }
      if ("tip_perc_3" in validationErrors) {
        validationErrors["tip_perc_3"] = "";
      }
      // Function to check if any tip value exists more than one times
      const isValueRepeatedMoreThanTwice = (data) => {
        const valueCounts = {};
        data.forEach((item) => {
          const { tip } = item;
          if (valueCounts[tip]) {
            valueCounts[tip]++;
          } else {
            valueCounts[tip] = 1;
          }
        });

        if (Object.values(valueCounts).some((count) => count > 1)) {
          const findKeyWithValueMoreThanTwo = (obj) => {
            for (const key in obj) {
              if (Object.hasOwnProperty.call(obj, key)) {
                if (obj[key] >= 2) {
                  return key;
                }
              }
            }
            return null;
          };
          const keyWithValueMoreThanTwo =
            findKeyWithValueMoreThanTwo(valueCounts);
          error =
            keyWithValueMoreThanTwo +
            ` Percentage Tip is already selected. Please select different Tip Percentage.`;
          return true;
        }
      };

      const isRepeatedMoreThanTwice = isValueRepeatedMoreThanTwice(tipsArray);
      const failed = allValue.map((fl) => {
        let data = fl.category_data.map((vl) => {
          if (
            (vl.key == "tip_perc_2" || vl.key == "tip_perc_3") &&
            key == "tip_perc_1" &&
            value == vl.value &&
            flag
          ) {
            flag = false;
            error =
              value +
              ` Percentage Tip is already selected. Please select different Tip Percentage.`;
            return true;
          }
          if (
            (vl.key == "tip_perc_1" || vl.key == "tip_perc_3") &&
            key == "tip_perc_2" &&
            value == vl.value &&
            flag
          ) {
            flag = false;
            error =
              value +
              ` Percentage Tip is already selected. Please select different Tip Percentage.`;
            return true;
          }
          if (
            (vl.key == "tip_perc_1" || vl.key == "tip_perc_2") &&
            key == "tip_perc_3" &&
            value == vl.value &&
            flag
          ) {
            flag = false;
            error =
              value +
              ` Percentage Tip is already selected. Please select different Tip Percentage.`;
            return true;
          }
        });
        return data;
      });
      return error;
    }
    return "";
  };

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value);
  };

  const handleSettingChange = (i, key, value) => {
    if (key == "print_on_large_printer") {
      setOptions([]);
      setOpen(false);
      if (value.toLowerCase() == "yes") {
        hideLargePrinterData(false);
        setLargePrinterName(null);
      } else {
        hideLargePrinterData(true);
        setLargePrinterName(null);
      }
      setAutoSelectedPrinter(null);
    }
    const allValueUpdated = [...allValue];
    const tipsArrayUpdated = [];
    allValue.map((fl) => {
      fl.category_data.map((vl, ind) => {
        if (i == ind && key == vl.key) {
          vl.value = value;
        }
        if (
          vl.key == "tip_perc_1" ||
          vl.key == "tip_perc_2" ||
          vl.key == "tip_perc_3"
        ) {
          tipsArrayUpdated.push({
            tip: vl.value,
          });
        }
        if (key == "print_on_large_printer" && value.toLowerCase() == "no") {
          if (vl.key == "large_printer_api_key") {
            vl.value = null;
          }
          if (vl.key == "large_printer") {
            vl.value = null;
          }
        }
      });
    });
    setSettings(allValueUpdated);
    setTipsData(tipsArrayUpdated);
  };

  const handleSettingBlur = (key, field) => {
    const error = validateField(field.value, key, field);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [key]: error,
    }));
  };

  const formik = useFormik({
    initialValues: {
      name:
        undefined != selectedPosName && null != selectedPosName
          ? selectedPosName
          : "",
      location_id:
        selectedLocation && null != selectedLocation ? selectedLocation : "",
    },
    enableReinitialize: true,
    // validationSchema: Yup.object({
    //   name: Yup.string()
    //     .required(Constants.POS_NAME_REQUIRED)
    //     .max(191, Constants.POS_NAME_LESS_OR_EQUAL_191_CHARACTER),
    //   // location_id: Yup.string()
    //   //   .required(Constants.POS_LOCATION_REQUIRED),
    // }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const payload = {
        allValue: JSON.stringify(allValue),
        permission: categoryName == "Credit / House Account Settings" ? Constants.GET_ALL_LOC_GLOBAL_SETTINGS_CREDIT_HOUSE_SETTINGS_API_PERMISSION : categoryName == "Basic Details" ? Constants.GET_ALL_LOC_GLOBAL_SETTINGS_BASIC_DETAILS_API_PERMISSION : "",
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.POS_CREATE_API_ROUTE,
      });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        Object.values(validationErrors).some((error) => !!error) ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };

  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" onKeyDown={handleKeyDown}>
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
          pb={3}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={"flex-start"}
            pt={{ base: "1.5rem", md: "3.06rem" }}
            pb={{ base: "1.5rem", md: "0" }}
            /* pl={"1.88rem"} */
            pl={{ base: "1rem", lg: "1.88rem" }}
            pr={{ base: "0.5rem", md: "1.88rem" }}
            gap={2}
          >
            <PosBreadCrumb
              handleClick={(i) => {
                props.navigate(Constants.SYS_ALL_GLOBAL_SETTINGS, {
                  state: stateData.state,
                });
              }}
              breadCrumNames={["Global", categoryName]}
              breadCrumTitle={categoryName}
            />
            <Spacer />
            <Stack direction={"row"} alignSelf={"flex-end"}>
              <PosFormButton
                onClick={() => {
                  props.navigate(Constants.SYS_ALL_GLOBAL_SETTINGS, {
                    state: stateData.state,
                  });
                }}
                buttonText={"Cancel"}
                CancelButton={true}
                isDisabled={buttonDisable}
              />
              <PosFormButton
                isDisabled={
                  Object.values(validationErrors).some((error) => !!error) ||
                  buttonDisable
                    ? true
                    : false
                }
                buttonsubmit={"Submit"}
                SubmitButton={true}
                onClick={formik.handleSubmit}
              />
            </Stack>
          </Flex>
        </Box>

        {allValue.map((data, i) => (
          <Box
            key={i} // Ensure each `data` object has a unique `id` property
            mt={{ base: 4, lg: 12 }}
            ml={{ base: 4, lg: 14 }}
            mr={{ base: 4, lg: 14 }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
            p="8"
          >
            {categoryName == 'Credit / House Account Settings' && (
              <Text
              fontSize="1.5rem"
              color={Colors.taxShow}
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.27px",
              }}
            >
              {data.category}
            </Text>
            )}
            {data.category_data.map((fl, index) => (
              <React.Fragment key={index}>
                {fl.input_type === 0 ? (
                  <Stack spacing={1}>
                    <PosLable
                      label={true}
                      name={fl.setting_display_name}
                      // mt="0.31rem"
                      fontWeight={"350"}
                    />
                    <PosInput
                      id={fl.key}
                      placeholder={fl.setting_display_name}
                      handleInputChange={(e) => {
                        handleSettingChange(index, fl.key, e.target.value);
                      }}
                      handleBlur={() => handleSettingBlur(fl.key, fl)}
                      inputValue={fl.value}
                      posInput={true}
                      inputType={"text"}
                      // inputError={formik.touched.name && formik.errors.name}
                    />
                    <Text color={Colors.errorColor}>
                      {validationErrors[fl.key]}
                    </Text>
                  </Stack>
                ) : (
                  <Stack spacing={1} mt="1rem">
                    <Box className="card flex justify-content-center">
                      {fl.options.length > 0 ? (
                        <Select
                          id={fl.key}
                          color="#010048"
                          value={fl.value}
                          onChange={(e) => {
                            handleSettingChange(index, fl.key, e.target.value);
                          }}
                          onClick={() => {
                            handleSettingBlur(fl.key, fl);
                          }}
                        >
                          <option hidden disabled value="">
                            Select an Option
                          </option>
                          {fl.options.map((data, i) => (
                            <option key={i} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Select value={""} id={fl.key}>
                          <option hidden disabled value="">
                            {"N.A."}
                          </option>
                        </Select>
                      )}
                    </Box>
                    <Text color={Colors.errorColor}>
                      {validationErrors[fl.key]}
                    </Text>
                  </Stack>
                )}
              </React.Fragment>
            ))}
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

export default WithRouter(SystemAllGlobalSettingsCreate);
