import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useRadioGroup,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useParams, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDrawer,
  PosDropDown,
  PosFormButton,
  PosLable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  createAccountSettings,
  getAllLocationsAdditionalSettingData,
} from "./LocationService";

const LocationPricing = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const [state, setState] = useState({ formData: {} });
  const [selectedSizeOptions, setSelectedProductSizesOptions] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const { id } = useParams();
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [viewFlag, setViewFlag] = useState(false);

  useEffect(() => {
    if (props.isPricingOpen) {
      myContext.handleLoading(true);
      fetchData(id);
    }
    return () => {};
  }, [props.isPricingOpen]);

  const PRICING_TEMPLATES = Constants.PROD_TEMPLATE_OPTIONS;
  const handleSelectedTemplate = (value) => {
    if (
      selectedTemplate != null &&
      "" != selectedTemplate &&
      undefined != selectedTemplate
    ) {
      if (value == selectedTemplate) {
        setSelectedTemplate(null);
      } else {
        setSelectedTemplate(value);
      }
    } else {
      setSelectedTemplate(value);
    }
  };

  const {
    getRootProps: getTemplateRootProps,
    getRadioProps: getTemplateRadioProps,
  } = useRadioGroup({
    name: "selectedTemplate",
    defaultValue: selectedTemplate,
    onChange: handleSelectedTemplate,
  });

  const findAndReturnValue = (array, criteria) => {
    const foundObject = array.find((item) => item.name == criteria);
    if (foundObject) {
      return foundObject;
    } else {
      return null;
    }
  };

  const templateGroup = getTemplateRootProps();
  const fetchData = (id) => {
    try {
      getAllLocationsAdditionalSettingData(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            if (
              null != response.data?.data?.location?.prod_price_temp_size &&
              response.data?.data?.location?.prod_price_temp_size != "" &&
              undefined != response.data?.data?.location?.prod_price_temp_size
            ) {
              setSelectedSize(
                findAndReturnValue(
                  response.data?.data?.prod_price_sizes_options,
                  response.data?.data?.location?.prod_price_temp_size
                )
              );
            }
            setSelectedProductSizesOptions(
              response.data?.data?.prod_price_sizes_options
            );
            setSelectedTemplate(response.data?.data?.location?.prod_price_temp);
            myContext.handleLoading(false);
            setViewFlag(true);
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedTemplate:
        null != selectedTemplate &&
        "" != selectedTemplate &&
        undefined != selectedTemplate
          ? selectedTemplate
          : "",
      selectedSize:
        null != selectedSize && "" != selectedSize && undefined != selectedSize
          ? selectedSize
          : "",
    },
    validationSchema: Yup.object({
      selectedTemplate: Yup.string().required(Constants.POS_NAME_REQUIRED),
      selectedSize: Yup.object().required(Constants.POS_NAME_REQUIRED),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      let payload = {
        pricing_template: true,
        location_id: id,
        prod_price_temp:
          null != selectedTemplate &&
          "" != selectedTemplate &&
          undefined != selectedTemplate
            ? selectedTemplate
            : "",
        prod_price_temp_size:
          null != selectedSize &&
          "" != selectedSize &&
          undefined != selectedSize
            ? selectedSize.name
            : "",
      };
      setButtonDisable(true);
      myContext.handleLoading(true);
      createAccountSettings(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    },
  });
  const handleSelectedSize = (value) => {
    setSelectedSize(value);
  };
  const handleClose = () => {
    formik.resetForm();
    setViewFlag(false);
    if (props.onPricingClose) {
      props.onPricingClose();
    }
  };
  const CardRadio = ({ children, isChecked, ...props }) => {
    return (
      <Box as="label">
        <input type="radio" {...props} style={{ display: "none" }} />
        <Box
          bg={"white"}
          mt={{base: "1rem", md: 0}}
          // w={"20rem"}
          w={{base: "15rem", md: "20rem"}}
          h={"auto"}
          cursor="pointer"
          borderWidth="3px"
          borderRadius="md"
          boxShadow="md"
          // bg={isChecked ? "teal.600" : "white"}
          color={isChecked ? "white" : "black"}
          borderColor={isChecked ? Colors.primeTaxInfo : "rgba(109, 145, 254, 0.08)"}
          _hover={{
            bg: isChecked ? "messenger" : "gray.100",
          }}
          _checked={{
            bg: "teal.600",
            color: "white",
            borderColor: "#5881FE",
          }}
          _focus={
            {
              // boxShadow: "outline",
            }
          }
          px={5}
          py={3}
        >
          {children}
        </Box>
      </Box>
    );
  };

  return (
    <PosDrawer
      visible={viewFlag}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      <Box pr={{ base: 0, md: 4}} pl={{ base: 0, md: 4}} pt={4} pb={4}>
        {/* <Card
          sx={{
            boxShadow: "none",
          }}
        >
          <CardHeader alignItems={{ base: "flex-start" }} mt={10}> */}
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
        >
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={5}>
              <PosBreadCrumb
                handleClick={(i) => {
                  props.navigate(Constants.ESTABLISHMENT_LOCATION_PATH);
                }}
                breadCrumNames={["Locations", "Pricing Template"]}
                breadCrumTitle={"Pricing Template"}
              />
            </Box>
            {/* <Spacer /> */}
            <ButtonGroup  gap="2" alignSelf={"flex-end"} mb={2}>
              {/* <PosFormButton
                onClick={() => {
                  handleClose();
                }}
                buttonText={"Cancel"}
                CancelButton={true}
                isDisabled={buttonDisable}
              /> */}
              <PosFormButton
                isDisabled={
                  buttonDisable ||
                  selectedTemplate == null ||
                  selectedTemplate == "" ||
                  selectedSize == null ||
                  selectedSize == ""
                    ? true
                    : false
                }
                buttonsubmit={"Save Changes"}
                SubmitButton={true}
                onClick={formik.handleSubmit}
              />
            </ButtonGroup>
          </Flex>
        </Box>
        {/* </CardHeader> */}
        <Divider borderColor="#E6E6E6" mt={4} mb={10} />
        {/* <CardBody> */}
        <Stack divider={<StackDivider />} spacing="4">
          <Box>
            <Flex
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Box
                w={{ base: "100%", md: "35%" }}
                ml={{ base: "0", md: "1.9rem" }}
              >
                <Heading
                  as="h3"
                  size="xs"
                  fontWeight="500"
                  fontSize="1.125rem"
                  lineHeight="1.40625rem"
                  letterSpacing="-0.016875rem"
                  color="#010048"
                >
                  Select a Template Size
                </Heading>
                <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                  Choose the appearance of your pricing cards
                </Text>
              </Box>
              <Box w={{ base: "100%", md: "70%" }}>
                <Box
                  w={{ base: "100%", md: "42rem" }}
                  h={"auto"}
                  gap={{base: 0, md: "1.19rem"}}
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {PRICING_TEMPLATES.map((option, index) => {
                    const radio = getTemplateRadioProps({
                      value: option.value,
                    });
                    return (
                      <CardRadio
                        key={index}
                        {...radio}
                        isChecked={selectedTemplate == option.value}
                      >
                        <Flex
                          // bg={"red"}
                          flexDirection="row"
                          alignItems="center"
                          gap={6}
                        >
                          <Box
                            fontWeight="bold"
                            color={Colors.posTextInfo}
                            textAlign="center"
                          >
                            <Box mr={2}>
                              <img
                                alt="im"
                                src={
                                  process.env.PUBLIC_URL +
                                  `/${option.value}.png`
                                }
                                style={{ width: "50rem" }}
                              />
                            </Box>
                            <Box
                              bg={Colors.posviewbgcolor}
                              borderRadius={"0.3125rem"}
                            >
                              <Text color={Colors.posTextInfo}>
                                {option.name}
                              </Text>
                            </Box>
                          </Box>
                        </Flex>
                        <Text color={Colors.posTextInfo}>
                          {option.description}
                        </Text>
                      </CardRadio>
                    );
                  })}
                </Box>
                <PosLable
                  fontWeight={500}
                  label={true}
                  requiredLabel={true}
                  name={"Size:"}
                  mt="0.31rem"
                />
                <Box w={{ base: "19rem", md: "42rem" }} className="card flex justify-content-center">
                  <PosDropDown
                    width="100%"
                    lableAvailable={true}
                    id="selectedSize"
                    value={formik.values.selectedSize}
                    onChange={(e) => {
                      if(undefined != e.value && null != e.value && '' != e.value){
                        handleSelectedSize(e.target.value);
                      }
                    }}
                    options={selectedSizeOptions}
                    optionLabel={"display_name"}
                    className="w-full md:w-20rem"
                    // style={{ width: "100%" }}
                    placeholder={"Select Size"}
                  ></PosDropDown>
                  <Text color={Colors.errorColor}>
                    {formik.touched.selectedSize &&
                    formik.errors.selectedSize ? (
                      <span>{formik.errors.selectedSize}</span>
                    ) : null}
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Stack>
        {/* <Flex
          minWidth="max-content"
          gap="2"
          mt={{ base: "4.72rem", md: "4.72rem" }}
        >
          <Spacer />
          <ButtonGroup>
            <PosFormButton
              onClick={() => {
                handleClose();
              }}
              buttonText={"Cancel"}
              CancelButton={true}
            />
            <PosFormButton
              isDisabled={
                buttonDisable ||
                selectedTemplate == null ||
                selectedTemplate == "" ||
                selectedSize == null ||
                selectedSize == ""
                  ? true
                  : false
              }
              buttonsubmit={"Save Changes"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </ButtonGroup>
        </Flex> */}
        {/* </CardBody>
        </Card> */}
      </Box>
    </PosDrawer>
  );
};
export default WithRouter(LocationPricing);
