import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useParams, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDrawer,
  PosFormButton,
  PosInput,
  PosLable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  createAccountSettings,
  getAllLocationsAdditionalSettingData,
} from "./MasterAdminLocationService";

const MasterAdminLocationReceiptSettings = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const [state, setState] = useState({ formData: {} });
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [taxes, setTax] = useState([]);
  const [setSettings, setLocationAdditionalSettings] = useState([]);
  const { id } = useParams();
  const [viewFlag, setViewFlag] = useState(false);

  useEffect(() => {
    if (props.isReceiptOpen) {
      myContext.handleLoading(true);
      fetchData(id);
    }
    return () => {};
  }, [props.isReceiptOpen]);

  const fetchData = async (id) => {
    try {
      getAllLocationsAdditionalSettingData(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTax(response.data?.data?.tax);
            setSelectedTaxes(response.data?.data?.selected_taxes);
            setLocationAdditionalSettings(
              response.data?.data?.locationSettings
            );
            myContext.handleLoading(false);
            setViewFlag(true);
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };
  const findAndReturnValue = (array, criteria, valueToReturn) => {
    const foundObject = array.find((item) => item.key == criteria);
    if (foundObject) {
      return foundObject[valueToReturn];
    } else {
      return null;
    }
  };

  const formik = useFormik({
    initialValues: {
      print_header1: findAndReturnValue(setSettings, "print_header1", "value"),
      print_header2: findAndReturnValue(setSettings, "print_header2", "value"),
      print_footer1: findAndReturnValue(setSettings, "print_footer1", "value"),
      print_footer2: findAndReturnValue(setSettings, "print_footer2", "value"),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      print_header1: Yup.string()
        .trim()
        .required(Constants.RECEIPT_HEADER_1_REQUIRED)
        .max(999, Constants.RECEIPT_HEADER_1_MAX_999),
      print_header2: Yup.string()
        .trim()
        .required(Constants.RECEIPT_HEADER_2_REQUIRED)
        .max(999, Constants.RECEIPT_HEADER_2_MAX_999),
      print_footer1: Yup.string()
        .trim()
        .required(Constants.RECEIPT_FOOTER_1_REQUIRED)
        .max(999, Constants.RECEIPT_FOOTER_1_MAX_999),
      print_footer2: Yup.string()
        .trim()
        .required(Constants.RECEIPT_FOOTER_2_REQUIRED)
        .max(999, Constants.RECEIPT_FOOTER_2_MAX_999),
    }),
    onSubmit: async (values) => {
      setState({ ...state, formData: values });
      myContext.handleLoading(true);
      let payload = {
        ...values,
        receipt_settings: true,
        location_id: id,
      };
      setButtonDisable(true);
      myContext.handleLoading(true);
      createAccountSettings(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    },
  });
  const handleClose = () => {
    formik.resetForm();
    setViewFlag(false);
    if (props.onReceiptClose) {
      props.onReceiptClose();
    }
  };
  return (
    <PosDrawer
      visible={viewFlag}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      <Box padding={2}>
        {/* <Card
          sx={{
            boxShadow: "none",
          }}
        > */}
        {/* <CardHeader alignItems={{ base: "flex-start" }} mt={10}> */}
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
        >
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={{ base: 5, md: 0 }}>
              <PosBreadCrumb
                handleClick={(i) => {
                  props.navigate(
                    "/" +
                      process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
                      Constants.MASTER_ADMIN_LOCATIONS_PATH
                  );
                }}
                breadCrumNames={["Locations", "Receipts"]}
                breadCrumTitle={"Receipts"}
              />
            </Box>
            {/* <Spacer /> */}
            <ButtonGroup gap="2" alignSelf={"flex-end"}>
              {/* <PosFormButton
                onClick={() => {
                  // props.navigate(Constants.PRODUCT_LIST_PATH);
                  handleClose();
                }}
                buttonText={"Cancel"}
                CancelButton={true}
                isDisabled={buttonDisable}
              /> */}
              <PosFormButton
                isDisabled={
                  !formik.isValid || buttonDisable
                    ? // (formik.values.logout_time == true &&
                      //   (formik.values.ideal_time == "" ||
                      //     null == formik.values.ideal_time ||
                      //     2 > formik.values.ideal_time))
                      true
                    : false
                }
                buttonsubmit={"Save Changes"}
                SubmitButton={true}
                onClick={formik.handleSubmit}
              />
            </ButtonGroup>
          </Flex>
        </Box>
        {/* </CardHeader> */}
        <Divider borderColor="#E6E6E6" mt={5} />
        {/* <CardBody> */}
        <Stack divider={<StackDivider />} spacing="2">
          <Box>
            <Flex
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Box
                w={{ base: "100%", md: "35%" }}
                ml={{ base: "0", md: "1.9rem" }}
                mt={10}
              >
                <Heading
                  as="h3"
                  size="xs"
                  fontWeight="500"
                  fontSize="1.125rem"
                  lineHeight="1.40625rem"
                  letterSpacing="-0.016875rem"
                  color="#010048"
                >
                  Receipt Details
                </Heading>
                <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                  Manage and Customize receipt settings
                </Text>
              </Box>
              <Box w={{ base: "100%", md: "65%" }} mr={"0.9rem"}>
                <PosLable
                  fontWeight={500}
                  requiredLabel={true}
                  name={"Receipt Header 1: (Ex :- XYZ Market)"}
                  mt="0.31rem"
                />
                <PosInput
                  posInput={true}
                  id={"print_header1"}
                  inputType={"text"}
                  placeholder={"Enter Receipt Header 1"}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.print_header1 ||""}
                  handleBlur={formik.handleBlur}
                  inputError={
                    formik.touched.print_header1 && formik.errors.print_header1
                  }
                ></PosInput>
                <Text color={Colors.errorColor}>
                  {formik.touched.print_header1 &&
                  formik.errors.print_header1 ? (
                    <span>{formik.errors.print_header1}</span>
                  ) : null}
                </Text>
                <PosLable
                  fontWeight={500}
                  requiredLabel={true}
                  name={"Receipt Header 2: (Ex :- www.*****.com)"}
                  mt="0.31rem"
                />
                <PosInput
                  posInput={true}
                  id={"print_header2"}
                  inputType={"text"}
                  placeholder={"Enter Receipt Header 2"}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.print_header2 ||""}
                  handleBlur={formik.handleBlur}
                  inputError={
                    formik.touched.print_header2 && formik.errors.print_header2
                  }
                ></PosInput>
                <Text color={Colors.errorColor}>
                  {formik.touched.print_header2 &&
                  formik.errors.print_header2 ? (
                    <span>{formik.errors.print_header2}</span>
                  ) : null}
                </Text>
                <PosLable
                  fontWeight={500}
                  requiredLabel={true}
                  name={"Receipt Footer 1: (Ex :- Thank You For Shopping at)"}
                  mt="0.31rem"
                />
                <PosInput
                  posInput={true}
                  id={"print_footer1"}
                  inputType={"text"}
                  placeholder={"Enter Receipt Footer 1"}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.print_footer1 ||""}
                  handleBlur={formik.handleBlur}
                  inputError={
                    formik.touched.print_footer1 && formik.errors.print_footer1
                  }
                ></PosInput>
                <Text color={Colors.errorColor}>
                  {formik.touched.print_footer1 &&
                  formik.errors.print_footer1 ? (
                    <span>{formik.errors.print_footer1}</span>
                  ) : null}
                </Text>
                <PosLable
                  fontWeight={500}
                  requiredLabel={true}
                  name={"Receipt Footer 2: (Ex :- XYZ Market)"}
                  mt="0.31rem"
                />
                <PosInput
                  posInput={true}
                  id={"print_footer2"}
                  inputType={"text"}
                  placeholder={"Enter Receipt Footer 2"}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.print_footer2 ||""}
                  handleBlur={formik.handleBlur}
                  inputError={
                    formik.touched.print_footer2 && formik.errors.print_footer2
                  }
                ></PosInput>
                <Text color={Colors.errorColor}>
                  {formik.touched.print_footer2 &&
                  formik.errors.print_footer2 ? (
                    <span>{formik.errors.print_footer2}</span>
                  ) : null}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Stack>
        {/* <Flex
          minWidth="max-content"
          gap="2"
          mt={{ base: "4.72rem", md: "4.72rem" }}
        >
          <Spacer />
          <ButtonGroup>
            <PosFormButton
              onClick={() => {
                // props.navigate(Constants.PRODUCT_LIST_PATH);
                handleClose();
              }}
              buttonText={"Cancel"}
              CancelButton={true}
            />
            <PosFormButton
              isDisabled={
                formik.values.print_header1 === null ||
                !formik.isValid ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Save Changes"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </ButtonGroup>
        </Flex> */}
        {/* </CardBody>
        </Card> */}
      </Box>
    </PosDrawer>
  );
};
export default WithRouter(MasterAdminLocationReceiptSettings);
