import {
  Box,
  ButtonGroup,
  Flex,
  SimpleGrid,
  Text,
  useMediaQuery,
  useDisclosure,
} from "@chakra-ui/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useSubmit,
  useLocation,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
  ProductEditDrawer,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  commonDateFormate,
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";

const StoreCredit = (props) => {
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const stateData = useLocation();
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [totalcount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const effectRun = useRef(true);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { error } = PosErrorHook();
  const searchInputHideRef = useRef(false);
  const { addToast } = PosTostMessage();
  const [paginationModel, setPaginationModel] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "code",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const sortRef = useRef(sortModel);
  const [listOlColumnVisibilityModel, setOlColumnVisibilityModel] =
    React.useState({
      // id: false,
    });
  const actionColumnXS = isMobile ? { width: 120 } : { flex: 0.9 };
  const [timeoutId, setTimeoutId] = useState(null);
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        if (!searchInputHideRef.current && actionResponse.error.data === 0) {
          searchInputHideRef.current = true;
        }
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Store Credit Report exported successfully."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.STORE_CREDIT_REPORT_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          searchInputHideRef.current = false;
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          effectRun.current = false;
          setLoading(false);
          setButtonDisable(false);
          myContext.handleLoading(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);
  const formik = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      fetchStoreCreditData();
      myContext.handleLoading(false);
      effectRun.current = false;
    }
  }, [paginationModel, sortModel]);
  const fetchStoreCreditData = async () => {
    setLoading(true);
    let data = {
      getreport: true,
      dateFrom:
        formik.values.fromDate != null && formik.values.fromDate != ""
          ? reportDateFormate(formik.values.fromDate)
          : "",
      dateTo:
        formik.values.toDate != null && formik.values.toDate != ""
          ? reportDateFormate(formik.values.toDate)
          : "",
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      filter: searchQuery,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.STORE_CREDIT_REPORT,
    });
  };

  const exportData = async () => {
    myContext.handleLoading(true);
    let data = {
      getreport: false,
      type: "xls",
      dateFrom:
        formik.values.fromDate != null && formik.values.fromDate != ""
          ? reportDateFormate(formik.values.fromDate)
          : "",
      dateTo:
        formik.values.toDate != null && formik.values.toDate != ""
          ? reportDateFormate(formik.values.toDate)
          : "",
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      filter: searchQuery,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.STORE_CREDIT_REPORT,
    });
  };

  const clearFilterDAta = () => {
    effectRun.current = true;
    if (searchQuery.length > 0) {
      setSearchQuery("");
    }
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };

  const columnNames = [
    {
      field: "code",
      headerName: "Code",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "initial_amount",
      headerName: "Initial amount($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "balance",
      headerName: "Balance($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "created_by_name",
      headerName: "User",
      sortable: false,
      ...actionColumnXS,
    },
    {
      field: "view",
      headerName: "View",
      filterable: false,
      sortable: false,
      resizable: false,
      width: 120,
      disableExport: true,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          let id = rowData.id;
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          myContext.handleLoading(true);
          props.navigate(`${Constants.STORE_CREDIT_VIEW + id}`, {
            state: data,
          });
        };
        return (
          <PosIconButton
            name={Constants.VIEW_BUTTON}
            onClick={handleView}
            viewIcon={true}
            width={"4.625rem"}
          />
        );
      },
    },
  ];
  const modifiedData = tableData.map((data, i) => {
    return {
      id: data.id,
      code: data.code,
      initial_amount: data.initial_amount,
      balance: data.balance,
      created_by_name: data.created_by_name,
      created_at: data.created_at,
      //   order_id: data.order_id,
      //   local_id: data.local_id,
    };
  });

  const handleSearchList = () => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };

  const handleClear = () => {
    effectRun.current = true;
    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };

  const apply = () => {
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      effectRun.current = true;
      apply();
    }, 250);
    setTimeoutId(newTimeoutId);
  };
  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Store Credits"]}
          breadCrumTitle={"Store Credits"}
        />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={exportData}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>

      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        {!searchInputHideRef.current ? (
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={onChange}
              onSearch={handleSearchList}
              handleClear={handleClear}
            />
          </Flex>
        ) : null}
        {loading ? (
          <PosProgress />
        ) : totalcount > 0 ? (
          <Box padding={"1rem"}>
            <PosDataGridTable
              columns={columnNames}
              rows={modifiedData}
              totalcount={totalcount}
              columnVisibilityModel={listOlColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setOlColumnVisibilityModel(newModel)
              }
              paginationModel={paginationModel}
              paginationMode="server"
              sortingMode="server"
              onPaginationModelChange={(newModel) => {
                effectRun.current = true;
                setPaginationModel(newModel);
              }}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                effectRun.current = true;
                sortRef.current = sortModel;
                setSortModel(newSortModel);
              }}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </Box>
        ) : (
          <Box padding={"1rem"}>
            <PosNoDataFound title={Constants.STORE_CREDIT_NOT_FOUND} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default WithRouter(StoreCredit);
