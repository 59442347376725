import {
  Box,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import { SimpleReportLayout } from "../../../../../layouts/index";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosTable,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
  onlyDateFormate,
  twofix
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { getRefundWithOrderLineData } from "./RefundWithoutOrderService";
const RefundWithoutOrder = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const stateData = useLocation();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [orderRefundData, setOrderRefundData] = useState([]);
  const [orderRefundDatas, setOrderRefundDatas] = useState([]);
  const [itemWiseData, setItemWiseData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [totalcounts, setTotalCounts] = useState(0);
  const [locations, setLocations] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [loading, setLoading] = useState(true);
  const [applyClicked, setApplyClicked] = useState(false);
  const [isExtraLarges] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const pageRef = useRef(false);
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    external_reference: false,
    reason: false,
  });
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 80 },
    {
      field: "location_name",
      headerName: "Location",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "pos_station",
      headerName: "Pos Station",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "employee_name",
      headerName: "Emp Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "refund_type",
      headerName: "Refund Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "amount_refund",
      headerName: "Refund Amt($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "tax_refund",
      headerName: "Refund Tax($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "total_refunded_amount",
      headerName: "Total Refund($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "created_at",
      headerName: "Refund Date",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "external_reference",
      headerName: "external_reference",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "reason",
      headerName: "reason",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "view",
      headerName: "View",
      sortable: false,
      disableExport: true,
      resizable: false,
      filterable: false,
      width:100,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          myContext.handleLoading(true);
          handleRefundWithoutOrder(rowData);
          // onOpen();
        };
        return (
          <PosIconButton
            name={Constants.VIEW_BUTTON}
            onClick={handleView}
            viewIcon={true}
            width={"4.625rem"}
          />
        );
      },
    },
  ];

  const handleRefundWithoutOrder = (rowData) => {
    myContext.handleLoading(true);
    let data = {
      id: rowData.id,
      payment_type: rowData.payment_type,
      external_reference: rowData.external_reference,
      reason: rowData.reason,
    };
    try {
      getRefundWithOrderLineData(data, { id: rowData.id })
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const orderRefunds = response.data.data.orderRefund;
            const itemWiseData = response.data.data.itemWiseRefund;
            const totalcounts = response.data.data.itemWiseRefund.length;
            setOrderRefundData(orderRefunds);
            setOrderRefundDatas(rowData);
            setItemWiseData(itemWiseData);
            setTotalCounts(totalcounts);
            setTimeout(() => {
              onOpen(true);
              // setLoading(false);
              myContext.handleLoading(false);
            }, 100);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLoactions:  undefined !== loaderResponse?.data?.data?.location &&
      null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1
        ? loaderResponse?.data?.data?.location : [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLoading(true);
          if(undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1){
              setOlColumnVisibilityModel(prev => ({...prev, location_name: false }));
            }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      getRefundWithoutOrderData();
    }
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Refund Without Order Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, "RefundWithoutOrder.xlsx");
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.refundWithoutOrderData);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
          myContext.handleLoading(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
      if (
        formik.values.selectedLoactions.length > 0 &&
        null != formik.values.selectedLoactions &&
        "" != formik.values.selectedLoactions
      ) {
        if (
          null != formik.values.fromDate &&
          "" != formik.values.fromDate){
          if(null != formik.values.toDate &&
          "" != formik.values.toDate
        ) {
      myContext.handleLoading(true);
      let data = {
        exportReport: true,
        // location: JSON.stringify({
        //     id: formik.values.selectedLoactions.id,
        //     name: formik.values.selectedLoactions.name
        // }),
        location: JSON.stringify(formik.values.selectedLoactions),
        dateFrom: reportDateFormate(formik.values.fromDate),
        dateTo: reportDateFormate(formik.values.toDate),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        type: "xls",
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };

      submit(data, {
        method: Constants.POST,
        path: Constants.REFUND_WITHOUT_ORDER,
      });
    } else {
      clearTableData(Constants.TO_DATE_IS_REQUIRED);
    }
  } else {
    clearTableData(Constants.FORM_DATE_IS_REQUIRED);
  } } else {
    clearTableData(Constants.LOCATION_IS_REQUIRED);
  }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getRefundWithoutOrderData = () => {
    if (formik.values.selectedLoactions.length > 0) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          setButtonDisable(true);
          setLoading(true);
          setShowTable(false);
          setApplyClicked(true);
          let data = {
            getreport: true,
            // location: JSON.stringify({
            //     id: formik.values.selectedLoactions.id,
            //     name: formik.values.selectedLoactions.name
            // }),
            location: JSON.stringify(formik.values.selectedLoactions),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            query: JSON.stringify({
              limit: paginationModel.pageSize,
              page: paginationModel.page + 1,
              order:
                sortModel.length > 0
                  ? sortModel[0].field
                  : sortRef.current[0].field,
              order_type:
                sortModel.length > 0
                  ? sortModel[0].sort
                  : sortRef.current[0].sort,
            }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.REFUND_WITHOUT_ORDER,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedLoactions", e.value);
  };
  const handleResetAllData = () => {
    pageRef.current = false;
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
    setApplyClicked(false);
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        // let s moment(e.value).set({ hour: 23, minute: 59 }).toDate();
        // formik.setFieldValue("fromDate", start_date);
        // formik.setFieldValue("toDate", end_date);
        // setFromDateView(start_date);
        // setToDateView(end_date);
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  function reportDateFormates(dateString) {
    if (!dateString) {
      return "";
    }
    const date = moment(dateString);
    const formattedDate = date.format("MM/DD/YYYY");
    return formattedDate;
  }
  const modifiedData = tableData.map((data) => ({
    id: data.id,
    location_name: data.location_name,
    pos_station: data.pos_station,
    employee_name: data.employee_name,
    refund_type: data.refund_type,
    amount_refund: data.amount_refund,
    tax_refund: data.tax_refund,
    total_refunded_amount: data.total_refunded_amount,
    payment_type: data.payment_type == "STR_CR" ? "STORE CREDIT" : data.payment_type,
    created_at: onlyDateFormate(data.created_at),
    external_reference: data.external_reference,
    reason: data.reason,
  }));

  const { isOpen, onOpen, onClose } = useDisclosure();

  const itemWiseViewColumns = [
    {
      columnNames: "Sr No",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Product Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Quantity",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Amount($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Tax Amount($)",
      sortName: "",
      width: "",
    },
  ];
  let counter = 1;
  const orderWiseViewRaw = orderRefundData.map((data) => ({
    payment_type: data.payment_type == "STR_CR" ? "STORE CREDIT" : data.payment_type || "N.A",
    amount: data.amount,
    tax_amount: data.tax_amount,
  }));
  const totalAmount = orderWiseViewRaw.reduce(
    (total, row) => total + Number(row.amount),
    0
  );
  const totalTax = orderWiseViewRaw.reduce(
    (total, row) => total + Number(row.tax_amount),
    0
  );
  orderWiseViewRaw.push({
    amount: totalAmount.toFixed(2),
    tax_amount: totalTax.toFixed(2),
  });
  const itemWiseViewRaw = itemWiseData.map((data) => ({
    id: counter++,
    // product_name: data.product_name,
    product_name: data.unit ? `${data.product_name}, ${data.unit}` : data.product_name,
    quantity: data.quantity,
    amount: data.amount,
    tax_amount: data.tax_amount,
  }));
  const totalItemAmount = itemWiseViewRaw.reduce(
    (total, row) => total + Number(row.amount),
    0
  );
  const totalItemTax = itemWiseViewRaw.reduce(
    (total, row) => total + Number(row.tax_amount),
    0
  );
  itemWiseViewRaw.push({
    sr_no: "Total",
    product_name: "-",
    quantity: "-",
    amount: totalItemAmount.toFixed(2),
    tax_amount: totalItemTax.toFixed(2),
  });
  const renderReportLayout = () => {
    return (
      <>
        <Box className="card flex justify-content-center">
          <PosDropDown
            options={locations}
            value={formik.values.selectedLoactions}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            resetFilterOnHide={true}
            multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations*"
            width={
              isExtraLarges
                ? "20rem"
                : isLarge
                ? "17rem"
                : isMeduim1
                ? "13.32rem"
                : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
            maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              value={formik.values.fromDate}
              label={"From Date*"}
              readOnlyInput={true}
              width={
                isExtraLarges
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              value={formik.values.toDate}
              label={"To Date*"}
              width={
                isExtraLarges
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              readOnlyInput={true}
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Modal size={"6xl"} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>{orderRefundData.length>0 ? 'Item Wise Refund Details' : 'Order Refund Details' }</ModalHeader> */}
          <ModalCloseButton top="1.9rem" right="1.5rem" />
          <ModalBody>
            {orderRefundData.length > 0 && (
              <>
                <Box
                  sx={{ bg: Colors.modifierTable }}
                  mt={{ base: "1rem", md: "1rem" }}
                >
                  <PosListHeader header={"Order Refund Details"} />
                </Box>
                <Box
                  // maxHeight="1.13rem"
                  overflowY="auto"
                  ml={{ base: 0, md: "0.5rem" }}
                  mt={{ base: "0.5rem", md: "1rem" }}
                  style={{
                    fontSize: "1.13rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                >
                  <Text mb={{ base: 0, md: "1rem" }}>
                    Total Refunded Amount: ${twofix(totalAmount)}
                  </Text>
                  <Text mb={{ base: 0, md: "1rem" }}>
                    Total Refunded Tax Amount: ${twofix(totalTax)}
                  </Text>
                  <Text mb={{ base: 0, md: "1rem" }}>
                    Payment Type: {orderRefundDatas.payment_type}
                  </Text>
                  <Text mb={{ base: 0, md: "1rem" }}>
                    External Reference: {orderRefundDatas.external_reference}
                  </Text>
                  <Text>Reason: {orderRefundDatas.reason}</Text>
                </Box>
              </>
            )}
            {itemWiseData.length > 0 && (
              <>
                <Box
                  sx={{ bg: Colors.modifierTable }}
                  mt={{ base: "1rem", md: "1rem" }}
                >
                  <PosListHeader header={"Item Wise Refund Details"} />
                </Box>
                <Box ml={{ base: 0, md: "0.5rem" }}>
                  <Text
                    mb={{ base: 0, md: "0.5rem" }}
                    mt={{ base: "1rem", md: "1rem" }}
                    style={{
                      fontSize: "1.13rem",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                  >
                    Payment Type: {orderRefundDatas.payment_type}
                  </Text>
                  <Text
                    mb={{ base: 0, md: "0.5rem" }}
                    style={{
                      fontSize: "1.13rem",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                  >
                    External Reference: {orderRefundDatas.external_reference}
                  </Text>
                  <Text
                    style={{
                      fontSize: "1.13rem",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                  >
                    Reason: {orderRefundDatas.reason}
                  </Text>
                  <Box mt={4} maxHeight="18rem" overflowY="auto">
                    {loading ? (
                      <PosProgress />
                    ) : totalcounts > 0 ? (
                      <PosTable
                        columnNames={itemWiseViewColumns}
                        rawdata={itemWiseViewRaw}
                        totalcount={orderRefundData.length}
                        footerHide={true}
                        noAction={true}
                        sortColumnName={null}
                        page={null}
                        rowsPerPage={null}
                        order={null}
                      />
                    ) : (
                      <PosNoDataFound title={Constants.NO_DATA_FOUND} />
                    )}
                  </Box>
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Refund Without Order"]}
          breadCrumTitle={"Refund Without Order"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={"space-between"}
        >
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isMeduim ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              isDisabled={applyClicked ? false : true}
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.selectedLoactions ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable
              }
              onClick={getRefundWithoutOrderData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                // onPaginationModelChange={setPaginationModel}
                // sortModel={sortModel}
                // onSortModelChange={(newSortModel) => {
                //   sortRef.current = sortModel;
                //   setSortModel(newSortModel);
                // }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.REFUND_WITHOUT_ORDER_REPORT_CSV}
              />
            </Flex>
          ) : (
            <PosNoDataFound title={Constants.REFUND_WITHOUT_ORDER_NO_DATA} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(RefundWithoutOrder);
