import axios from "axios";
import * as Constants from "../../../../../constants/Constants";

export function getStoreCreditReportData({ request, params }) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      let formData = await request.formData();
      if (Object.fromEntries(formData).getreport === "true") {
        const payload = JSON.stringify({
          dateFrom: Object.fromEntries(formData).dateFrom,
          dateTo: Object.fromEntries(formData).dateTo,
          zone: Object.fromEntries(formData).zone,
          page: Object.fromEntries(formData).page,
          limit: Object.fromEntries(formData).limit,
          order: Object.fromEntries(formData).order,
          order_type: Object.fromEntries(formData).order_type,
          filter: Object.fromEntries(formData).filter,
        });
        axios
          .post(
            Constants.REACT_APP_API_URL +
              process.env.REACT_APP_API_VERSION +
              Constants.STORE_CREDIT_REPORT_API_ROUTE,
            payload,
            {
              headers: {
                "content-type": Constants.CONTANT_TYPE,
                Authorization: "Bearer" + token,
                Permission: Constants.STORE_CREDIT_REPORT_API_PERMISSION,
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            let errorData = {
              error: true,
              response: error.response,
              code: error.code
            };
            resolve(errorData);
          });
      } else {
        const payload = JSON.stringify({
          dateFrom: Object.fromEntries(formData).dateFrom,
          dateTo: Object.fromEntries(formData).dateTo,
          zone: Object.fromEntries(formData).zone,
          page: Object.fromEntries(formData).page,
          limit: Object.fromEntries(formData).limit,
          order: Object.fromEntries(formData).order,
          order_type: Object.fromEntries(formData).order_type,
          filter: Object.fromEntries(formData).filter,
        });
        axios
          .post(
            Constants.REACT_APP_API_URL +
              process.env.REACT_APP_API_VERSION +
              Constants.STORE_CREDIT_REPORT_EXPORT_API_ROUTE,
            payload,
            {
              headers: {
                "content-type": Constants.CONTANT_TYPE,
                Authorization: "Bearer" + token,
                Permission: Constants.STORE_CREDIT_REPORT_API_PERMISSION,
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            let errorData = {
              error: true,
              response: error.response,
              code: error.code
            };
            resolve(errorData);
          });
      }
    });
  }

  export function getStorecreditview({ request, params }) {
    return new Promise(async (resolve, reject) => {
      const id = params.id;
      let token = localStorage.getItem("token");
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            `${Constants.STORE_CREDIT_VIEW_API_ROUTE + "/" + id}`,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.STORE_CREDIT_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    });
  }

  export function getStoreCreditDetailsPaginate(payload) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.GET_STORE_CREDIT_DETAILS_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.STORE_CREDIT_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }