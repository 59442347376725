import {
  Box,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  ButtonGroup,
} from "@chakra-ui/react";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useParams,
  useSubmit,
  useLocation,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
// import warnignIcon from "../../../assets/images/Ellipse21.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosNoDataFound,
  PosProgress,
  PosTable,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import { twofix, commonDateFormate } from  "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { getStoreCreditDetailsPaginate } from "./StoreCreditService";

const StoreCreditView = (props) => {
  const [isScreenSize480] = useMediaQuery("(max-width: 3rem)");
  const stateData = useLocation();
  const [giftCardData, setGiftCardSetData] = useState([]);
  const [giftCardDatas, setGiftCardSetDatas] = useState([]);
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const [giftCardId, setGiftCardSetId] = useState();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [totalcount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [storeCreditDetailsData, setStoreCreditDetailsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id");
  const effectRun = useRef(true);
  const { id } = useParams();
  const { addToast } = PosTostMessage();
  const [giftCardDataBalance, setGiftCardDataBalance] = useState([]);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setGiftCardSetData(loaderResponse.data.data.giftCardData);
          setGiftCardSetDatas(loaderResponse.data.data.giftCardData.user);
          setGiftCardSetId(loaderResponse.data.data.giftCardData.id);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.GIFT_CARD_VIEW + id);
        fetchData(id);
        setGiftCardDataBalance(actionResponse.data.data.gift_card_balance);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.GIFT_CARD_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    effectRun.current = true;
    setLoading(true);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
    effectRun.current = true;
    setLoading(true);
  };
  const fetchData = async (id) => {
    let data = {
      limit: rowsPerPage,
      page: page + 1,
      id: parseInt(id),
    };
    try {
      getStoreCreditDetailsPaginate(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setStoreCreditDetailsData(
              response.data.data.giftCardAuditTrailData
            );
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setStoreCreditDetailsData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
            setStoreCreditDetailsData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setStoreCreditDetailsData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      fetchData(id);
      // return () => {
      effectRun.current = false;
      // };
    }
    if (loaderResponse && loaderResponse.data && loaderResponse.data.data) {
      const newBalance = loaderResponse.data.data.giftCardData.balance;
      setGiftCardDataBalance((prevState) => ({
        ...prevState,
        balance: newBalance,
      }));
    }
  }, [
    sortType,
    searchQuery,
    rowsPerPage,
    page,
    loading,
    sortColumn,
    loaderResponse,
  ]);
  const columnNames = [
    {
      columnNames: "Sr No.",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Date(UTC)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Description",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Amount($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Balance($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "User",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Order Number",
      sortName: "",
      width: "",
    },
    // {
    //   columnNames: "Location",
    //   sortName: "",
    //   width: "",
    // },
    
  ];
  function reportDateFormat(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedDate = `${month} ${day}, ${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  }
  const modifiedData =
  storeCreditDetailsData?.map((Giftcard) => {
      return {
        id: Giftcard.sr_no,
        date: reportDateFormat(Giftcard.date),
        description: Giftcard.description,
        amount: Giftcard.amount,
        type: Giftcard.type,
        balance: Giftcard.balance,
        user: Giftcard.user,
        order_number: Giftcard.order_number || "N.A",
      };
    }) || [];

  
  return (
    <Box bg={Colors.loginAuthBackground} flex="1">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: 4, md: "3.06rem" }}
        pb={{ base: 4, md: "2rem" }}
        pl={{ base: 4, lg: "1.88rem" }}
        pr={{ base: 4, md: "2.75rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.STORE_CREDIT_REPORT, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Store Credits", "View Store Credit"]}
          />
        </Box>
        <Spacer />
      </Flex>
      <Box
        mt={{ base: 4, md: "0.63rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "start" }}
          pt={{ base: "1.5rem", md: "2.13rem" }}
          pb={{ base: "1.5rem", md: "0.88rem" }}
          pl={{ base: "1rem", lg: "2.06rem" }}
          pr={{ base: "1rem", md: "1.75rem" }}
          gap={2}
        >
          <Box>
            <Text
              fontSize="1.13rem"
              color={Colors.posTextInfo}
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
              }}
            >
              Store Credit Details
            </Text>

            <Text
              color={Colors.taxCreateExemptEbt}
              style={{
                fontSize: "0.94rem",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
            >
              Your personal details for your OctoPos Store Credit Customer
            </Text>
          </Box>
          <Spacer />
          <ButtonGroup gap="2" alignSelf={"flex-end"}>
            <PosFormButton
              buttonText={"Cancel"}
              CancelButton={true}
              onClick={() => {
                props.navigate(Constants.STORE_CREDIT_REPORT, {
                  state: stateData.state,
                });
              }}
            />
          </ButtonGroup>
        </Flex>
        <Divider />
        <Flex
          gap="9"
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center" }}
          p={{ base: "4", md: "8" }}
        >
          <Box
            flex="1"
            width="100%"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
          >
            <Flex
              mt={1.5}
              height={"4.88rem"}
              p={2.5}
              direction="column"
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <Text
                ml={2.5}
                mt={2.5}
                color={Colors.posTextInfo}
                fontWeight={300}
              >
                Current Balance
              </Text>
              <Text ml={2.5} color={Colors.taxInfo}>
                {giftCardDataBalance.balance !== null &&
                giftCardDataBalance.balance !== undefined &&
                giftCardDataBalance.balance !== ""
                  ? `$${giftCardDataBalance.balance}`
                  : ""}
              </Text>
            </Flex>
            <Flex
              mt={{ base: "1rem", md: "2.31rem" }}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Date Created(UTC)
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {commonDateFormate(giftCardData.created_at)}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Created by
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{giftCardDatas.name}</Text>
            </Flex>
            {/* <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Email Address
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardData.email !== null && giftCardData.email !== ""
                  ? giftCardData.email
                  : "N.A."}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <LocalPhoneIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Phone Number
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardData.phone !== null && giftCardData.phone !== ""
                  ? giftCardData.phone
                  : "N.A."}
              </Text>
            </Flex> */}
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Code
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{giftCardData.code}</Text>
            </Flex>
          </Box>

          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            width="100%"
          >
            <Flex
              mt={1.5}
              height={"4.88rem"}
              p={2.5}
              direction="column"
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <Text
                ml={2.5}
                mt={2.5}
                color={Colors.posTextInfo}
                fontWeight={300}
              >
                Initial Amount
              </Text>
              <Spacer />
              <Text ml={2.5} color={Colors.taxInfo}>
                {giftCardData.initial_amount !== null &&
                giftCardData.initial_amount !== undefined &&
                giftCardData.initial_amount !== ""
                  ? `$${giftCardData.initial_amount}`
                  : ""}
              </Text>
            </Flex>
            <Flex
              mt={{ base: "1rem", md: "2.31rem" }}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Initial Amount
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardData.initial_amount !== null &&
                giftCardData.initial_amount !== undefined &&
                giftCardData.initial_amount !== ""
                  ? `$${giftCardData.initial_amount}`
                  : ""}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Current Balance
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardDataBalance.balance !== null &&
                giftCardDataBalance.balance !== undefined &&
                giftCardDataBalance.balance !== ""
                  ? `$${giftCardDataBalance.balance}`
                  : ""}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Payment Type
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardData.payment_types}
              </Text>
            </Flex>
            {/* <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Payment External Reference
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardData.payment_external_reference !== null &&
                giftCardData.payment_external_reference !== ""
                  ? giftCardData.payment_external_reference
                  : "N.A."}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Type
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{giftCardData.type}</Text>
            </Flex> */}
          </Box>
        </Flex>
      </Box>
      <Box
        mt={{ base: 4, md: "0.63rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "satrt" }}
          pt={{ base: "1.5rem", md: "3.25rem" }}
          pb={{ base: "1.5rem", md: "2rem" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.56rem" }}
          gap={2}
        >
          <Box>
            <Text
              fontSize="1.13rem"
              color={Colors.posTextInfo}
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
              }}
            >
              Store Credit Details
            </Text>
          </Box>
        </Flex>
        <Box mb={12}>
          {loading ? (
            <PosProgress />
          ) : modifiedData.length > 0 ? (
            <PosTable
              columnNames={columnNames}
              rawdata={modifiedData}
              noAction={true}
              totalcount={totalcount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              sortColumnName={sortColumn}
              page={page}
              rowsPerPage={rowsPerPage}
              order={sortType}
            />
          ) : (
            <PosNoDataFound />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default WithRouter(StoreCreditView);
