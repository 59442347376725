import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useMediaQuery
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";
import {
  AppContext,
  PosFormButton,
  PosListHeader,
} from "../../../components/index";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

import { BarChart } from "@mui/x-charts/BarChart";
import { lineElementClasses } from "@mui/x-charts/LineChart";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { ChevronDownIcon } from "@chakra-ui/icons";
import { DateRangePicker } from "react-date-range";
import {
  getDashboardTopPerformingCouponApi,
  getDashboardTotalCouponRedeemedDataApi,
  getDashboardTotalEmailSentApi,
  getDashboardTotalEmailUnsubApi,
  getDashboardTotalRevenueApi,
  getDashboardTotalSMSSentApi,
  getDashboardTotalSMSUnsubApi,
} from "../campaign/CampaignService";

const moment = require("moment");

const MarketingDashboard = (props) => {
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const [isExtraLarge] = useMediaQuery("(min-width: 1595px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1365px)");
  const [isMeduim] = useMediaQuery("(min-width: 1280px)");

  const effectRun = useRef(true);

  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const myContext = useContext(AppContext);

  // Set All Chart Loading.
  const [lineChartLoading, setLineChartLoading] = useState(false);

  const [couponRedeemed, setCouponRedeemed] = useState('');
  const [totalRevenue, setTotalRevenue] = useState('');
  const [totalSmsSent, setTotalSmsSent] = useState('');
  const [totalEmailSent, setTotalEmailSent] = useState('');
  const [emailUnsubscribed, setEmailUnsubscribed] = useState('');
  const [smsUnsubscribed, setSmsUnsubscribed] = useState('');
  const [couponUsageDetails, setCouponUsageDetails] = useState([]);
  const [couponNames, setCouponUsageNames] = useState([]);
  const [isOpen, setIsOpen] = useState(false);


  // Calender Default Setting
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 6);
  const [dateRange, setDateRange] = useState([
    {
      startDate: sevenDaysAgo,
      endDate: today,
      key: "selection",
    },
  ]);

  // Calender Popover
  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  // Select Date from Calender Popover
  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  // On Loading all locations, last created order location and tenant order available check.
  useEffect(() => {
    if (effectRun.current === true) {
      let start_date = new Date(moment(dateRange[0]?.startDate));
      let end_date = new Date(moment(dateRange[0]?.endDate));
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      var rangeLabel = "Custom range";
      let payload = {
        myStartDate: myStartDate,
        myEndDate: myEndDate,
        local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      applyButton(payload);
    }
    effectRun.current = false;
  });

  const applyButton = (payload) => {
    setLineChartLoading(true);
    setIsOpen(false);
    setCouponRedeemed('');
    setTotalRevenue('');
    setTotalSmsSent('');
    setTotalEmailSent('');
    setEmailUnsubscribed('');
    setSmsUnsubscribed('');
    getTotalCouponsRedeem(payload);
    getTotalRevenue(payload);
    getTotalEmailSent(payload);
    getTotalSmsSent(payload);
    getTotalEmailUnsub(payload);
    getTotalSMSUnsub(payload);
    getTopPerformingCoupon(payload);
  };


  const getTotalCouponsRedeem = (req) => {
    setLineChartLoading(true);
    try {
      getDashboardTotalCouponRedeemedDataApi(req)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setCouponRedeemed(response.data?.data?.coupon_redeem_count);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } 
          else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getTotalRevenue = (req) => {
    setLineChartLoading(true);
    try {
      getDashboardTotalRevenueApi(req)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTotalRevenue(response.data?.data?.total_revenue);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } 
          else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getTotalEmailUnsub = (req) => {
    setLineChartLoading(true);
    try {
      getDashboardTotalEmailUnsubApi(req)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setEmailUnsubscribed(response.data?.data?.total_email_unsubscribed);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } 
          else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getTotalSMSUnsub = (req) => {
    setLineChartLoading(true);
    try {
      getDashboardTotalSMSUnsubApi(req)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setSmsUnsubscribed(response.data?.data?.total_sms_unsubscribed);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } 
          else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };


  const getTotalEmailSent = (req) => {
    setLineChartLoading(true);
    try {
      getDashboardTotalEmailSentApi(req)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTotalEmailSent(response.data?.data?.total_email_sent);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } 
          else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getTotalSmsSent = (id) => {
    setLineChartLoading(true);
    try {
      getDashboardTotalSMSSentApi(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTotalSmsSent(response.data?.data?.total_sms_sent);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } 
          else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getTopPerformingCoupon = (id) => {
    setLineChartLoading(true);
    try {
      getDashboardTopPerformingCouponApi(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            var topPerformingCoupons = [];
            var topPerformingCouponsName = [];
            if(undefined != response.data?.data?.top_performing_coupon && null != response.data?.data?.top_performing_coupon && '' != response.data?.data?.top_performing_coupon && response.data?.data?.top_performing_coupon.length > 0){
              response.data?.data?.top_performing_coupon.map( (item, index) => {
                if (item.coup_redeemed_count > 0){
                  // var dt = {
                  //   id: item.coupon_id,
                  //   value: item.coup_redeemed_count,
                  //   label:item.coupon_name
                  // }
                  topPerformingCoupons.push(item.coup_redeemed_count);
                  topPerformingCouponsName.push(item.coupon_name);
                }
              });
            }
            setCouponUsageDetails(topPerformingCoupons);
            setCouponUsageNames(topPerformingCouponsName);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } 
          else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };
  

  const customize = {
    legend: { hidden: true },
  };

  const formatNumber = (number) => {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleClick = () => {
    let start_date = new Date(moment(dateRange[0]?.startDate));
      let end_date = new Date(moment(dateRange[0]?.endDate));
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      var rangeLabel = "Custom range";
      let payload = {
        myStartDate: myStartDate,
        myEndDate: myEndDate,
        local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      setCouponUsageDetails([]);
      setCouponUsageNames([]);
      applyButton(payload);
    // Your additional logic here
  };
  //   setIsOpen(false);
  //   getLineChart(id, ordersAvailable);
  //   getPaymentMethod(id, ordersAvailable);
  //   getAverageBill(id, ordersAvailable);
  //   getItemByVolumn(id, ordersAvailable);
  //   getItemBySales(id, ordersAvailable);
  //   getRewardCustomers(id, ordersAvailable);
  //   if (process.env.REACT_APP_DOMAIN != "vori") {
  //     setDisplayOnlineGiftCardSalesLineChart(1);
  //     getOnlineGiftCardSalesLineChart(id, ordersAvailable);
  //   } else {
  //     setDisplayOnlineGiftCardSalesLineChart(0);
  //   }
  // };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
      >
        <PosListHeader header={"Marketing Dashboard"} 
        discription={"Manage and track key metrics on OctoPos, from coupon redemption to your overall customer intraction that helps you empower your business"} />
        <Spacer />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <Popover
            isOpen={isOpen}
            onOpen={togglePopover}
            onClose={togglePopover}
          >
            <PopoverTrigger>
              <Button
                rightIcon={<ChevronDownIcon />}
                variant="avtar"
                // h="28px"
                // fontSize={"sm"}
                fontWeight={300}
                lineHeight={5}
                color={"#010048"}
                backgroundColor={"rgba(88, 129, 254, 0.05)"}
                // _hover={{ bg: 'skyblue' }}
              >
                {moment(dateRange[0].startDate).format("D MMM, YYYY") +
                  " - " +
                  moment(dateRange[0].endDate).format("D MMM, YYYY")}
              </Button>
            </PopoverTrigger>
            <PopoverContent width="min-content">
              <PopoverArrow />
              <PopoverBody>
                <Flex direction={isMobile ? "column" : "row"} align="center">
                  <DateRangePicker
                    ranges={dateRange}
                    // presets={customRanges}
                    onChange={handleSelect}
                    // defaultValue={null}
                    rangeColors={["#126BE9"]}
                    months={2}
                    direction={isMobile ? "vertical" : "horizontal"}
                  />
                </Flex>
                <PosFormButton
                  buttonsubmit={"Apply"}
                  SubmitButton={true}
                  onClick={handleClick}

                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </ButtonGroup>
      </Flex>
      <Flex
        gap={"0.63rem"}
        flexDirection={!isMobile ? "row" : "column"}
        mt={"2rem"}
        // ml={!isMobile ? "3.06rem" : "1rem"}
        // mr={!isMobile ? "3.06rem" : "1rem"}
        // mb={!isMobile ? "3.06rem" : "1rem"}
      >
        <Box
          flex={1}
          flexShrink={0}
          overflow="hidden"
          bg={"#FEFEFE"}
          borderRadius={"0.625rem"}
          border={"0.06rem solid #F8F7F7"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            // align={"stretch"}
            // pl={{ base: "2rem", md: "2rem" }}
            // pt={{ base: "2.30rem", md: "2.30rem" }}
            // pr={{ base: "2rem", md: "2rem" }}
            // pb={{ base: "2.30rem", md: "2.30rem" }}
            // gap={"0rem"}
            align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
          >
              <Text
                color={"rgba(1, 0, 72, 0.50)"}
                fontSize={
                  isExtraLarge
                    ? "md"
                    : isLarge
                    ? "md"
                    : isMeduim1
                    ? "15px"
                    : isMeduim
                    ? "14px"
                    : "md"
                }
                fontWeight={"light"}
                width={"max-content"}
                display={"contents"}
              >
                Total Coupons Redeemed
              </Text>
            {lineChartLoading || couponRedeemed === '' ? (
              <Box color={"#00126833"} paddingTop={2}>
                <Stack>
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                </Stack>
              </Box>
            ) : (
              <>
                <Text
                  color={"#1E1E1E"}
                  fontSize={"2xl"}
                  fontWeight={"semibold"}
                >
                  {couponRedeemed}
                </Text>
              </>
            )}
          </Box>
        </Box>
        <Box
          flex={1}
          flexShrink={0}
          overflow="hidden"
          bg={"#FEFEFE"}
          borderRadius={"0.625rem"}
          border={"0.06rem solid #F8F7F7"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
          >
              <Text
                color={"rgba(1, 0, 72, 0.50)"}
                fontSize={"1.125rem"}
                fontWeight={"300"}
              >
                Total Revenue Generated
              </Text>
            {lineChartLoading || '' === totalRevenue ? (
              <Box color={"#00126833"} paddingTop={2}>
                <Stack>
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                </Stack>
              </Box>
            ) : (
              <>
                <Text
                  color={"#1E1E1E"}
                  fontSize={"2xl"}
                  fontWeight={"semibold"}
                >
                  ${totalRevenue == 0 ? "0.00" : formatNumber(totalRevenue)}
                </Text>
              </>
            )}
          </Box>
        </Box>
        <Box
          flex={1}
          flexShrink={0}
          overflow="hidden"
          bg={"#FEFEFE"}
          borderRadius={"0.625rem"}
          border={"0.06rem solid #F8F7F7"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
          >
            <Box>
              <Text
                color={"rgba(1, 0, 72, 0.50)"}
                fontSize={"1.125rem"}
                fontWeight={"300"}
              >
                Total SMS Sent
              </Text>
            </Box>
            {lineChartLoading || '' === totalSmsSent ? (
              <Box color={"#00126833"} paddingTop={2}>
                <Stack>
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                </Stack>
              </Box>
            ) : (
              <>
                <Text
                  color={"#1E1E1E"}
                  fontSize={"2xl"}
                  fontWeight={"semibold"}
                >
                  {totalSmsSent}
                </Text>
              </>
            )}
          </Box>
        </Box>
      </Flex>
      <Flex gap={"0.63rem"} mt={3} flexDirection={!isMobile ? "row" : "column"}>
        <Box
          flex={1}
          flexShrink={0}
          overflow="hidden"
          bg={"#FEFEFE"}
          borderRadius={"0.625rem"}
          border={"0.06rem solid #F8F7F7"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
          >
            <Box>
              <Text
                color={"rgba(1, 0, 72, 0.50)"}
                fontSize={"1.125rem"}
                fontWeight={"300"}
              >
                Total Email Sent
              </Text>
            </Box>
            {lineChartLoading || '' === totalEmailSent ? (
              <Box color={"#00126833"} paddingTop={2}>
                <Stack>
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                </Stack>
              </Box>
            ) : (
              <>
                <Text
                  color={"#1E1E1E"}
                  fontSize={"2xl"}
                  fontWeight={"semibold"}
                >
                  {totalEmailSent}
                </Text>
              </>
            )}
          </Box>
        </Box>
        <Box
          flex={1}
          flexShrink={0}
          overflow="hidden"
          bg={"#FEFEFE"}
          borderRadius={"0.625rem"}
          border={"0.06rem solid #F8F7F7"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
          >
            <Box>
              <Text
                color={"rgba(1, 0, 72, 0.50)"}
                fontSize={"1.125rem"}
                fontWeight={"300"}
              >
                Total Unsubscribed Email
              </Text>
            </Box>
            {lineChartLoading || '' === emailUnsubscribed? (
              <Box color={"#00126833"} paddingTop={2}>
                <Stack>
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                </Stack>
              </Box>
            ) : (
              <>
                <Text
                  color={"#1E1E1E"}
                  fontSize={"2xl"}
                  fontWeight={"semibold"}
                >
                  {emailUnsubscribed}
                </Text>
              </>
            )}
          </Box>
        </Box>
        <Box
          flex={1}
          flexShrink={0}
          overflow="hidden"
          bg={"#FEFEFE"}
          borderRadius={"0.625rem"}
          border={"0.06rem solid #F8F7F7"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
          >
              <Text
                color={"rgba(1, 0, 72, 0.50)"}
                fontSize={"1.125rem"}
                fontWeight={"300"}
              >
                Total Unsubscribed SMS
              </Text>
            {lineChartLoading || '' === smsUnsubscribed ? (
              <Box color={"#00126833"} paddingTop={2}>
                <Stack>
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                </Stack>
              </Box>
            ) : (
              <>
                <Text
                  color={"#1E1E1E"}
                  fontSize={"2xl"}
                  fontWeight={"semibold"}
                >
                  {smsUnsubscribed}
                </Text>
              </>
            )}
          </Box>
        </Box>
      </Flex>
      {couponUsageDetails.length > 0 && (
        <Flex mt={3} gap={"0.63rem"}>
          <Box
            flex={1}
            flexShrink={0}
            overflow="hidden"
            bg={"#FEFEFE"}
            borderRadius={"0.625rem"}
            border={"0.06rem solid #F8F7F7"}
            boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
          >
          <Flex align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }>
                    <Text
                      color={"rgba(1, 0, 72, 0.50)"}
                      fontSize={"1.125rem"}
                      fontWeight={"300"}
                    >
                      Top 5 Performing Coupon
                    </Text>
                </Flex>
                {lineChartLoading ? (
                  <Box color={"#00126833"} margin={30}>
                    <Stack>
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="120" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="180" />
                      <Skeleton height="10px" width="200" />
                    </Stack>
                  </Box>
                ) : (
                  <BarChart
                    grid={{ vertical: true }}
                    borderRadius={4}
                    // sx={{
                    //   [`& .${chartsGridClasses.line}`]: {
                    //     strokeDasharray: "8 4",
                    //     strokeWidth: 1,
                    //   },
                    // }}
                    sx={{
                      //bottom label color.
                      "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                        fill: "#615E83",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 400,
                      },
                      //side label color.
                      "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                        fill: "#615E83",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 400,
                      },
                      //both side label stroke black color remove.
                      "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                        stroke: "none",
                      },
                      //graph both dashed line color.
                      "& .MuiChartsGrid-root .MuiChartsGrid-line": {
                        stroke: "#E5E5EF",
                        shapeRendering: "crispEdges",
                        strokeWidth: 1,
                      },
                      //vertical line color and width.
                      "& .MuiChartsGrid-line.MuiChartsGrid-verticalLine": {
                        stroke: "#E5E5EF",
                        shapeRendering: "crispEdges",
                        strokeWidth: 1.5,
                        strokeDasharray: "8 4", // This makes the line dashed
                      },
                      //bottom and side axis line color change.
                      "& .MuiChartsAxis-root .MuiChartsAxis-line": {
                        stroke: "#E5E5EF",
                        shapeRendering: "crispEdges",
                        strokeWidth: 0,
                      },
                      //graph line color.
                      [`& .${lineElementClasses.root}`]: {
                        stroke: "#6585F7",
                        strokeWidth: 2,
                      },
                    }}
                    yAxis={[
                      {
                        scaleType: "band",
                        data: couponNames,
                        categoryGapRatio: 0.69,
                        // barGapRatio: 0.1,
                        tickPlacement: "middle",
                        colorMap: {
                          type: "ordinal",
                          colors: [
                            "#6D3AFF",
                            "#E642F4",
                            "#FD9393",
                            "#FFDB80",
                            "#F6D1A6",
                          ],
                        },
                        tickLabelStyle: {
                          textAnchor: "start",
                          dominantBaseline: "central",
                          // transform: " translateX(9px) translateY(-20px)",
                          transform:
                            couponNames.length == 1
                              ? " translateX(9px) translateY(-15%)"
                              : couponNames.length == 2
                              ? " translateX(9px) translateY(-11%)"
                              : couponNames.length == 3
                              ? " translateX(9px) translateY(-8%)"
                              : couponNames.length == 4
                              ? "translateX(9px) translateY(-5%)"
                              : "translateX(9px) translateY(-20px)", // 2 band = 70px // 1 band= 32%
                        },
                      },
                    ]}
                    slotProps={{
                      bar: {
                        clipPath: `inset(0px round 0px 4px 4px 0px)`,
                      },
                      noDataOverlay: { message: "" },
                    }}
                    xAxis={[
                      {
                        min: 0,
                      },
                    ]}
                    series={[{ data: couponUsageDetails }]}
                    height={315}
                    layout="horizontal"
                  />
                )}
            </Box>
        </Flex>
      )}
    </Box>
  );
};

export default WithRouter(MarketingDashboard);
