import { Box, Flex, Spacer, Stack, VStack, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../assets/colors/Colors";
import eye from "../../../../assets/images/Ellipse386.png";
import {
    AppContext,
    PosBreadCrumb,
    PosFormButton,
    PosInput,
    PosTostMessage,
    PosOutlineButton
} from "../../../../components/index";
import * as Constants from "../../../../constants/Constants";
import { PosErrorHook } from "../../../../hooks";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { WithRouter } from "../../../../navigators/WithRouter";
import { DeleteIcon } from "@chakra-ui/icons";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const UnitOfMeasureCreate = (props) => {
    const [buttonDisable, setButtonDisable] = useState(false);
    const myContext = useContext(AppContext);
    const { addToast } = PosTostMessage();
    const { error } = PosErrorHook();
    const submit = useSubmit();
    const loaderResponse = useLoaderData();
    const actionResponse = useActionData();
    const actionRun = useRef(false);
    const effectRun = useRef(true);
    const [unitOfMeasure, setUnitOfMeasure] = useState([]);
    const [isFormValid, setIsFormValid] = useState(true);
    const [unitOfMeasureItems, setUnitOfMeasureItems] = useState([]);
    const [isExtraLarges] = useMediaQuery("(min-width: 1100px)");
    const [isLarge] = useMediaQuery("(min-width: 1050px)");
    const [isMeduim1] = useMediaQuery("(min-width: 920px)");

    const handleDelete = (id) => {
        const isSelected = formik.values.unitOfMeasure.some(item => item.id === id && item.base_unit);
        if (isSelected) {
            addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.UNIT_OF_MEASURE_TITLE,
                alertDescription: Constants.UNIT_OF_MEASURE_SELECTION_CHECK,
            });
            return;
        }
        const updatedData = formik.values.unitOfMeasure.filter(item => item.id !== id);
        const newData = unitOfMeasureItems.filter(item => item.id === id);
        if (newData.length === 0) {
            formik.setFieldValue("unitOfMeasure", updatedData);
            setUnitOfMeasure(updatedData);
            const names = updatedData.map(item => item.name.trim().toUpperCase());
            const uniqueNames = new Set(names);
            const hasDuplicates = uniqueNames.size !== names.length;
            // Update error messages for all items
            const newDataArray = updatedData.map(item => ({
                ...item,
                nameErrors: hasDuplicates && names.filter(name => name === item.name.trim().toUpperCase()).length > 1
                    ? Constants.UNIT_OF_MEASURE_ALREADY_EXISTS
                    : ""
            }));
            setUnitOfMeasureError(newDataArray);
            setIsFormValid(!hasDuplicates);
        } else {
            addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.UNIT_OF_MEASURE_TITLE,
                alertDescription: Constants.UNIT_OF_MEASURE_DELETE_CHECK,
            });
            formik.setFieldValue("unitOfMeasure", updatedData);
            setUnitOfMeasure(updatedData);
            const names = updatedData.map(item => item.name.trim().toUpperCase());
            const uniqueNames = new Set(names);
            const hasDuplicates = uniqueNames.size !== names.length;
            setIsFormValid(!hasDuplicates);
            return;
        }
    };

    const addNewRow = () => {
        const hasEmptyField = formik.values.unitOfMeasure.some(item => !item.name.trim());
        if (hasEmptyField) {
            addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.UNIT_OF_MEASURE_TITLE,
                alertDescription: Constants.UNIT_OF_MEASURE_INPUT_ALREADY_EXISTS,
            });
            return;
        }
        const newRow = { id: Date.now(), name: "" };
        const updatedRows = [...formik.values.unitOfMeasure, newRow];
        setUnitOfMeasure(updatedRows);
        formik.setFieldValue("unitOfMeasure", updatedRows);
        setIsFormValid(true);
    };

    useEffect(() => {
        if (effectRun.current === true) {
            if (
                undefined != loaderResponse &&
                null != loaderResponse &&
                {} != loaderResponse
            ) {
                if (
                    undefined != loaderResponse?.error &&
                    null != loaderResponse?.error &&
                    loaderResponse?.error
                ) {
                    let actionData = loaderResponse;
                    error({ actionData });
                    myContext.handleLoading(false);
                } else if (
                    null !== loaderResponse.data[Constants.FLAGE] &&
                    true === loaderResponse.data[Constants.FLAGE]
                ) {
                    setUnitOfMeasureItems(loaderResponse.data.data.UnitOfMeasure);
                    myContext.handleLoading(false);
                }
            }
            effectRun.current = false;
        };
    }, [loaderResponse]);
    useEffect(() => {
        if (
            undefined != actionResponse &&
            null != actionResponse &&
            {} != actionResponse
        ) {
            myContext.handleLoading(false);
            setButtonDisable(false);
            if (
                undefined != actionResponse?.error &&
                null != actionResponse?.error &&
                actionResponse?.error
            ) {
                myContext.handleLoading(false);
                let actionData = actionResponse;
                error({ actionData });
            } else if (
                actionResponse.data[Constants.FLAGE] !== null &&
                actionResponse.data[Constants.FLAGE] === true
            ) {
                myContext.handleLoading(false);
                props.navigate(Constants.SYS_ALL_GLOBAL_SETTINGS);
                addToast({
                    alertStatus: Constants.TOAST_TYPE_SUCESS,
                    alertTitle: Constants.UNIT_OF_MEASURE_TITLE,
                    alertDescription: actionResponse.data.message,
                });
            }
        }
    }, [actionResponse]);

    useEffect(() => {
        formik.setFieldValue(
            "unitOfMeasure",
            unitOfMeasureItems.map((item) => ({
                id: item.id,
                name: item.name,
                default_flag: item.default_flag,
                base_unit: item.base_unit
            }))
        );
    }, [unitOfMeasureItems]);

    // const handleUnitOFMeasureChange = (index, field, value) => {
    //     const updatedUnitOfMeasure = [...formik.values.unitOfMeasure];
    //     if (updatedUnitOfMeasure[index]) {
    //         updatedUnitOfMeasure[index][field] = value;
    //         formik.setFieldValue("unitOfMeasure", updatedUnitOfMeasure);
    //         const currentItem = updatedUnitOfMeasure[index];
    //         if (currentItem && currentItem.name) {
    //             const trimmedName = currentItem.name.trim().toUpperCase();
    //             const isUnique = updatedUnitOfMeasure.every(
    //                 (item, i) => item.id === currentItem.id || item.name.trim().toUpperCase() !== trimmedName
    //             );
    //             if (!isUnique) {
    //                 addToast({
    //                     alertStatus: Constants.TOAST_TYPE_WARNING,
    //                     alertTitle: Constants.UNIT_OF_MEASURE_TITLE,
    //                     alertDescription: Constants.UNIT_OF_MEASURE_ALREADY_EXISTS,
    //                 });
    //                 setIsFormValid(false);
    //             } else {
    //                 const names = updatedUnitOfMeasure.map(item => item.name.trim().toUpperCase());
    //                 const uniqueNames = new Set(names);
    //                 const hasDuplicates = uniqueNames.size !== names.length;
    //                 if (hasDuplicates) {
    //                     setIsFormValid(false);
    //                 } else {
    //                     setIsFormValid(true);
    //                 }
    //             }
    //         }
    //     }
    // };
    const [unitOfMeasureError, setUnitOfMeasureError] = useState([]);
    const handleUnitOFMeasureChange = (index, field, value) => {
        const updatedUnitOfMeasure = [...formik.values.unitOfMeasure];
        if (updatedUnitOfMeasure[index]) {
            updatedUnitOfMeasure[index][field] = value;
            // Update the formik field value
            formik.setFieldValue("unitOfMeasure", updatedUnitOfMeasure);
            // checkForDuplicates(updatedUnitOfMeasure, index);
            checkForDuplicates(updatedUnitOfMeasure);
        }
    };

    const [selectedIndex, setSelectedIndex] = useState(null);
    const handleSelectType = (index, selectedValue) => {
        // setSelectedIndex(index);
        // let i = formik.values.unitOfMeasure.findIndex(x=>x.id == index)
        // if (i>-1){
        //     let unitofmeasure = formik.values.unitOfMeasure 
        //     unitofmeasure[i].base_unit = 1
        // }
        const updatedUnitOfMeasure = formik.values.unitOfMeasure.map((item, i) => ({
            ...item,
            base_unit: i === index ? 1 : 0,
        }));
        formik.setFieldValue("unitOfMeasure", updatedUnitOfMeasure);
    };


    // const checkForDuplicates = (updatedUnitOfMeasure) => {
    //     const names = updatedUnitOfMeasure.map(item => item.name.trim().toUpperCase());
    //     const nameCounts = names.reduce((acc, name) => {
    //         acc[name] = (acc[name] || 0) + 1;
    //         return acc;
    //     }, {});
    //     const newDataArray = updatedUnitOfMeasure.map((item, index) => {
    //         const trimmedName = item.name.trim().toUpperCase();
    //         return {
    //             ...item,
    //             nameErrors: nameCounts[trimmedName] > 1 ? Constants.UNIT_OF_MEASURE_ALREADY_EXISTS : ""
    //         };
    //     });
    //     const hasDuplicates = Object.values(nameCounts).some(count => count > 1);
    //     const hasEmptyFields = updatedUnitOfMeasure.some(item => item.name.trim() === "");
    //     setUnitOfMeasureError(newDataArray);
    //     // setIsFormValid(Object.values(nameCounts).every(count => count <= 1));
    //     setIsFormValid(!hasDuplicates && !hasEmptyFields);
    // };
    const checkForDuplicates = (updatedUnitOfMeasure) => {
        const nonEmptyNames = updatedUnitOfMeasure
            .filter(item => item.name.trim() !== "")
            .map(item => item.name.trim().toUpperCase());
        const nameCounts = nonEmptyNames.reduce((acc, name) => {
            acc[name] = (acc[name] || 0) + 1;
            return acc;
        }, {});
        const newDataArray = updatedUnitOfMeasure.map(item => {
            const trimmedName = item.name.trim().toUpperCase();
            return {
                ...item,
                nameErrors:
                    trimmedName !== "" && nameCounts[trimmedName] > 1
                        ? Constants.UNIT_OF_MEASURE_ALREADY_EXISTS
                        : "",
            };
        });
        // const newDataArray = updatedUnitOfMeasure.map(item => {
        //     const trimmedName = item.name.trim().toUpperCase();
        //     let nameError = "";
        //     if (item.name.trim() === "") {
        //         nameError = Constants.UNIT_OF_MEASURE_NAME_REQUIRED;
        //     } else if (nameCounts[trimmedName] > 1) {
        //         nameError = Constants.UNIT_OF_MEASURE_ALREADY_EXISTS;
        //     }
        //     return {
        //         ...item,
        //         nameErrors: nameError,
        //     };
        // });
        setUnitOfMeasureError(newDataArray);
        const hasDuplicates = Object.values(nameCounts).some(count => count > 1);
        const hasEmptyFields = updatedUnitOfMeasure.some(item => item.name.trim() === "");
        setIsFormValid(!hasDuplicates && !hasEmptyFields);
    };


    // const checkForDuplicates = (updatedUnitOfMeasure, index) => {
    //     const currentItem = updatedUnitOfMeasure[index];
    //     if (currentItem && currentItem.name) {
    //         const trimmedName = currentItem.name.trim().toUpperCase();
    //         const names = updatedUnitOfMeasure.map(item => item.name.trim().toUpperCase());
    //         const isUnique = names.filter((name, i) => i !== index).every(name => name !== trimmedName);
    //         const newDataArray = [...unitOfMeasureError];
    //         newDataArray[index] = {
    //             ...currentItem,
    //             nameErrors: isUnique ? "" : Constants.UNIT_OF_MEASURE_ALREADY_EXISTS
    //         };
    //         setUnitOfMeasureError(newDataArray);
    //         setIsFormValid(names.length === new Set(names).size);
    //     }
    // };

    const handleBlur = (index) => {
        const updatedUnitOfMeasure = [...formik.values.unitOfMeasure];
        // checkForDuplicates(updatedUnitOfMeasure, index);
        checkForDuplicates(updatedUnitOfMeasure);
    };

    const columnViewNames = [
        {
            columnNames: "Default Base Unit",
            sortName: "",
            width: "",
        },
        {
            columnNames: "Name",
            sortName: "",
            width: "",
            textAlign: "start",
        },
        {
            columnNames: "Action",
            sortName: "",
            width: "",
        },
    ];
    const formik = useFormik({
        initialValues: {
            unitOfMeasure: unitOfMeasureItems.length
                ? unitOfMeasureItems.map((item) => ({
                    id: item.id,
                    name: item.name,
                    default_flag: item.default_flag,
                    base_unit: item.base_unit
                }))
                : [],
            name: "",
            base_unit: ""
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .trim()
            // .required(Constants.UNIT_OF_MEASURE_NAME_REQUIRED)
        }),
        onSubmit: async (values) => { },
    });

    const handleSubmitButton = () => {
        myContext.handleLoading(true);
        const unit_of_measures = formik.values.unitOfMeasure.map(item => ({
            id: item.id,
            name: item.name,
            base_unit: item.base_unit ? item.base_unit : 0
        }));
        const payload = {
            unit_of_measures: JSON.stringify(unit_of_measures),
        };
        actionRun.current = true;
        setButtonDisable(true);
        submit(payload, {
            method: Constants.POST,
            path: Constants.UNIT_OF_MEASURE_NAME_REQUIRED,
        });
    };

    const handleKeyDown = (event) => {
        if (
            event.key === "Enter" &&
            !(unitOfMeasure.length === 0 ||
                buttonDisable
                ? true
                : false)
        ) {
            formik.handleSubmit();
        }
    };
    return (
        <Flex direction="column" bg={Colors.loginAuthBackground} pb="3.31rem">
            <Box flex="1" onKeyDown={handleKeyDown}>
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    alignItems="flex-start"
                    pt={{ base: '1.5rem', md: '3.06rem' }}
                    pb={{ base: '1.5rem', md: '1rem' }}
                    pl={{ base: '1rem', lg: '1.88rem' }}
                    pr={{ base: '0.5rem', md: '1.88rem' }}
                    gap={2}
                    bg={Colors.loginAuthBackground}
                    position="sticky"
                    top={{ base: "3.7rem", md: "4.7rem" }}
                    zIndex="sticky"
                >
                    <PosBreadCrumb
                        handleClick={() => props.navigate(Constants.SYS_ALL_GLOBAL_SETTINGS)}
                        breadCrumNames={["Global Settings", ""]}
                        breadCrumTitle={"Unit Of Measure Settings"}
                    />
                    <Spacer />
                    <Stack direction="row" alignSelf="flex-end" spacing={2}>
                        <PosFormButton
                            onClick={() => props.navigate(Constants.SYS_ALL_GLOBAL_SETTINGS)}
                            buttonText="Cancel"
                            CancelButton={true}
                            isDisabled={buttonDisable}
                        />
                        <PosFormButton
                            isDisabled={
                                formik.values.unitOfMeasure.length <= 0 ||
                                unitOfMeasure.some(item => !item.name.trim()) ||
                                !isFormValid ||
                                buttonDisable
                            }
                            buttonsubmit="Submit"
                            SubmitButton={true}
                            onClick={handleSubmitButton}
                        />
                    </Stack>
                </Flex>
                <Box
                    mt={{ base: 4, lg: 12 }}
                    ml={{ base: 4, lg: 14 }}
                    mr={{ base: 4, lg: 14 }}
                    borderRadius="0.63rem"
                    bg={Colors.posPageDataBackground}
                    boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                    p={8}
                >
                    <Text
                        color={Colors.posTextInfo}
                        style={{
                            fontSize: "1.13rem",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "normal",
                            letterSpacing: "-0.02rem"
                        }}
                    >
                        Units for Unit of Measure
                    </Text>
                    <Box mt={{ base: 4, md: '1.69rem' }}>
                        <Text
                            color={Colors.posShowFields}
                            style={{
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 300,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                            }}
                        >
                            If you want to set up multiple units of measure for inventory, add them here.
                        </Text>
                        <Text
                            color={Colors.posShowFields}
                            style={{
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 300,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                            }}
                        >
                            Examples:
                        </Text>
                        <VStack
                            mt="0.25rem"
                            ml="1.69rem"
                            align="flex-start"
                            spacing={2}
                        >
                            <Box display="flex" alignItems="center">
                                <Image src={eye} boxSize="0.55rem" mr={"0.55rem"} />
                                <Text ml="0.5rem" color={Colors.posShowFields} style={{
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: 300,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                }}>
                                    You order bottles of juice in cases of 24 bottles. You sell the juices by the bottles, or pack of 4 or a full case.
                                </Text>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Image src={eye} boxSize="0.55rem" mr={"0.55rem"} />
                                <Text ml="0.5rem" color={Colors.posShowFields}
                                    style={{
                                        fontSize: "0.94rem",
                                        fontStyle: "normal",
                                        fontWeight: 300,
                                        lineHeight: "normal",
                                        letterSpacing: "-0.01rem",
                                    }}>
                                    You receive cans of beer in packs of six and a case of 4 packs. You sell the beer by the cans, or pack of six or a full case.
                                </Text>
                            </Box>
                        </VStack>
                    </Box>
                    <Text
                        mt="3.81rem"
                        fontSize="1.13rem"
                        color={Colors.posTextInfo}
                        fontWeight={500}
                        lineHeight="normal"
                        letterSpacing="-0.02rem"
                    >
                        Units in your Business
                    </Text>
                    <Text
                        mt="0.44rem"
                        color={Colors.posShowFields}
                        style={{
                            fontSize: "0.94rem",
                            fontStyle: "normal",
                            fontWeight: 300,
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                        }}
                    >
                        You can add all possible units for your business. For example, pack of 4, cases, pallets etc.
                    </Text>
                    <Flex direction={{ base: "column", md: "row" }} mt="1rem">
                        <Flex
                            width={
                                isExtraLarges
                                    ? "43rem"
                                    : isLarge
                                        ? "40rem"
                                        : isMeduim1
                                            ? "37rem"
                                            : "100%"
                            }
                        >
                            <TableContainer>
                                <Table aria-label="simple table" sx={{
                                    borderBottom: formik.values.unitOfMeasure.length - 1 ? "0.19rem solid #FAFAFA" : "none",
                                }}>
                                    <TableHead sx={{ backgroundColor: Colors.modifierTable }}>
                                        <TableRow>
                                            {columnViewNames.map((column, index) => (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: "0.94rem",
                                                        lineHeight: "1.18rem",
                                                        letterSpacing: "-0.02rem",
                                                        fontStyle: "normal",
                                                        maxHeight: "42rem",
                                                        color: Colors.posTextInfo
                                                    }}
                                                >
                                                    <Flex
                                                        flexDirection={"row"}
                                                        style={{
                                                            justifyContent: column?.columnNames === "Action"
                                                                ? "center"
                                                                : "start",
                                                        }}
                                                    >
                                                        {column.columnNames}
                                                    </Flex>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {formik.values.unitOfMeasure.map((item, index) => {
                                            return (
                                                <TableRow
                                                    key={item.id}
                                                    sx={{
                                                        borderBottom: index === formik.values.unitOfMeasure.length - 1 ? "0.19rem solid #FAFAFA" : "none",
                                                    }}
                                                >
                                                    <TableCell padding={0} sx={{ paddingLeft: "0.3rem", paddingRight: "0.3rem", paddingTop: "0.3rem", paddingBottom: "0.3rem",width: "6.3rem" }}>
                                                        <Box marginLeft={"0.8rem"}>
                                                        <RadioGroup
                                                            defaultValue="base_unit" value={parseInt(item.base_unit) ? item.id : ""}
                                                            onChange={(e) => {
                                                                handleSelectType(index, parseInt(e.target.value));
                                                            }}
                                                        >
                                                            <FormControlLabel value={item.id} control={<Radio />} sx={{margin: 0,padding: 0,}}/>
                                                        </RadioGroup>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell key={index}>
                                                        <PosInput
                                                            id={`unitOfMeasureItems.${index}.name`}
                                                            placeholder=""
                                                            handleInputChange={(e) =>
                                                                handleUnitOFMeasureChange(index, "name", e.target.value)
                                                            }
                                                            sx={{
                                                                color: "#010048",
                                                                fontSize: "0.94rem",
                                                                fontStyle: "normal",
                                                                fontWeight: 500,
                                                                textAlign: "start",
                                                                lineHeight: "normal",
                                                                letterSpacing: "-0.02rem"
                                                            }}
                                                            // width={{ base: "100%", md: "30rem" }}
                                                            inputValue={formik.values.unitOfMeasure[index]?.name || ""}
                                                            onBlur={() => handleBlur(index)}
                                                            width={
                                                                isExtraLarges
                                                                    ? "27rem"
                                                                    : isLarge
                                                                        ? "24rem"
                                                                        : isMeduim1
                                                                            ? "21rem"
                                                                            : "27rem"
                                                            }
                                                            variant={"posBasicInput"}
                                                            posInput={true}
                                                            inputType={"text"}
                                                            inputError={Boolean(unitOfMeasureError[index]?.nameErrors)}
                                                            onKeyDown={(e) => {
                                                                const currentValue = formik.values.unitOfMeasure[index]?.name || "";
                                                                if ((e.key === " " && currentValue === "") || e.key === "-") {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                        {unitOfMeasureError[index]?.nameErrors && (
                                                            <Text color={Colors.errorColor}>
                                                                {unitOfMeasureError[index].nameErrors}
                                                            </Text>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {index === unitOfMeasureItems.length - 0 ? (
                                                            <Flex justifyContent="center" alignItems="center" width={"5rem"}>
                                                                <Box>
                                                                    <DeleteIcon
                                                                        color={Colors.posCancleButtonMuiRed}
                                                                        style={{
                                                                            width: "1.5rem",
                                                                            height: "1.5rem",
                                                                            flexShrink: 0,
                                                                            cursor: "pointer",
                                                                        }}

                                                                        onClick={() => handleDelete(item.id)}
                                                                    />
                                                                </Box>
                                                            </Flex>
                                                        ) : (
                                                            <Flex justifyContent="center" alignItems="center">
                                                                {item.default_flag === 0 ? (
                                                                    <Text width={"5rem"}>A unit is assigned to a product.</Text>
                                                                ) : (
                                                                    <Flex justifyContent="center" alignItems="center" width={"3rem"}>
                                                                        <Box>
                                                                            <DeleteIcon
                                                                                color={Colors.posCancleButtonMuiRed}
                                                                                style={{
                                                                                    width: '1.5rem',
                                                                                    height: '1.5rem',
                                                                                    flexShrink: 0,
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => handleDelete(item.id)}
                                                                            />
                                                                        </Box>
                                                                    </Flex>
                                                                )}
                                                            </Flex>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        <TableRow>
                                            <TableCell colSpan={columnViewNames.length} style={{ textAlign: 'center' }}>
                                                <Flex justifyContent="start" alignItems="start">
                                                    <PosOutlineButton
                                                        name="Add"
                                                        display="flex"
                                                        width="8.5rem"
                                                        height="2.81rem"
                                                        padding="0.63rem"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        gap="0.31rem"
                                                        flexShrink="0"
                                                        onClick={addNewRow}
                                                    />
                                                </Flex>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Flex>
                    </Flex>
                </Box>
            </Box>
        </Flex>
    );
};

export default WithRouter(UnitOfMeasureCreate);
