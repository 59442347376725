import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
  PosTable,
  PosListHeader,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
  onlyDateFormate,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { getRefundWithOrderLineData } from "./RefundWithOrderService";
import { SimpleReportLayout } from "../../../../../layouts/index";

const RefundWithOrder = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const stateData = useLocation();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [orderRefundData, setOrderRefundData] = useState([]);
  const [itemWiseData, setItemWiseData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [totalDailySales, seltTotalDailySales] = useState([]);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnSM = isMobile ? { width: 140 } : { flex: 0.8 };
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [applyClicked, setApplyClicked] = useState(false);
  const pageRef = useRef(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const paginationRef = useRef(paginationModel);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });

  const columnNames = [
    { field: "id", headerName: "Order Id", sortable: true, width: 120 },
    {
      field: "local_id",
      headerName: "Local Id",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "pos_station",
      headerName: "Pos Station",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "location",
      headerName: "Location",
      sortable: true,
      ...actionColumnSM,
    },
    {
      field: "balance",
      headerName: "Total($)",
      sortable: true,
      ...actionColumnSM,
    },
    {
      field: "amount_refund",
      headerName: "Refund Amt($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "tax_refund",
      headerName: "Refund Tax($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "total_refunded_amount",
      headerName: "Total Refund($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "tip_refund",
      headerName: "Refund Tip($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "view",
      headerName: "View",
      sortable: false,
      width: 100,
      resizable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          myContext.handleLoading(true);
          handleOrderLineReportData(rowData);
        };
        return (
          <PosIconButton
            name={Constants.VIEW_BUTTON}
            onClick={handleView}
            viewIcon={true}
            width={"4.625rem"}
          />
        );
      },
    },
  ];
  const handleOrderLineReportData = (rowData) => {
    let data = {
      getreport: true,
      location_id: rowData.location_id,
      dateFrom: reportDateFormate(formik.values.fromDate),
      dateTo: reportDateFormate(formik.values.toDate),
      id: rowData.id,
    };
    try {
      getRefundWithOrderLineData(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const orderRefunds = response.data.data.filter(
              (item) => !item.product_name
            );
            const itemWiseData = response.data.data.filter(
              (item) => item.product_name
            );
            setOrderRefundData(orderRefunds);
            setItemWiseData(itemWiseData);
            onOpen();
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedLoactions:
        undefined !== loaderResponse?.data?.data?.location &&
        null !== loaderResponse?.data?.data?.location &&
        loaderResponse?.data?.data?.location?.length === 1
          ? loaderResponse?.data?.data?.location
          : [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setOlColumnVisibilityModel((prev) => ({
              ...prev,
              location: false,
            }));
          }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    // getRefundOrderData();/
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      if (paginationRef.current !== paginationModel) {
        getRefundOrderData();
        paginationRef.current = paginationModel;
      } else if (sortRef.current !== sortModel) {
        getRefundOrderData();
        sortModel.current = sortModel;
      }
    }
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Refund Report successfully exported."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.REFUND_WITH_ORDER_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.refund.data);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
    if (formik.values.selectedLoactions.length > 0) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          myContext.handleLoading(true);
          let data = {
            getreport: false,
            location: JSON.stringify(formik.values.selectedLoactions),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            type: "xls",
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.REFUND_WITH_ORDER_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getRefundOrderData = () => {
    if (formik.values.selectedLoactions.length > 0) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          setButtonDisable(true);
          setLoading(true);
          setShowTable(false);
          setApplyClicked(true);
          let data = {
            getreport: true,
            location: JSON.stringify(formik.values.selectedLoactions),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            query: JSON.stringify({
              limit: paginationModel.pageSize,
              page: paginationModel.page + 1,
              order:
                sortModel.length > 0
                  ? sortModel[0].field
                  : sortRef.current[0].field,
              order_type:
                sortModel.length > 0
                  ? sortModel[0].sort
                  : sortRef.current[0].sort,
            }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.REFUND_WITH_ORDER_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedLoactions", e.value);
  };
  const handleResetAllData = () => {
    pageRef.current = false;
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setApplyClicked(false);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        // let s moment(e.value).set({ hour: 23, minute: 59 }).toDate();
        // formik.setFieldValue("fromDate", start_date);
        // formik.setFieldValue("toDate", end_date);
        // setFromDateView(start_date);
        // setToDateView(end_date);
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const renderReportLayout = () => {
    return (
      <>
        <Box className="card flex justify-content-center">
          <PosDropDown
            options={locations}
            value={formik.values.selectedLoactions}
            onChange={handleOptionSelect}
            resetFilterOnHide={true}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations*"
            width={
              isExtraLarge
                ? "20rem"
                : isLarge
                ? "17rem"
                : isMeduim1
                ? "13.32rem"
                : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
            maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              readOnlyInput={true}
              value={formik.values.fromDate}
              label={"From Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              readOnlyInput={true}
              value={formik.values.toDate}
              label={"To Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  const modifiedData = tableData.map((data) => ({
    id: data.id,
    local_id: data.local_id,
    pos_station: data.pos_station,
    location: data.location,
    balance: data.balance,
    amount_refund: data.amount_refund,
    tax_refund: data.tax_refund,
    total_refunded_amount: data.total_refunded_amount,
    tip_refund: data.tip_refund,
    location_id: data.location_id,
  }));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orderWiseViewColumns = [
    {
      columnNames: "Sr No	",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Amount",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Tax Amount	",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Tip Amount	",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Payment Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "External Reference",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Reason",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Refund Date",
      sortName: "",
      width: "",
    },
  ];
  let counter = 1;
  const orderWiseViewRaw = orderRefundData.map((data) => ({
    id: counter++,
    amount: data.amount,
    tax_amount: data.tax_amount,
    tip_amount: data.tip_amount,
    payment_type: data.payment_type == "STR_CR" ? "STORE CREDIT" : data.payment_type,
    external_reference: data.external_reference,
    reason: data.reason,
    // created_at: data.created_at,
    created_at: onlyDateFormate(data.created_at),
  }));
  const totalAmount = orderWiseViewRaw.reduce(
    (total, row) => total + Number(row.amount),
    0
  );
  const totalTax = orderWiseViewRaw.reduce(
    (total, row) => total + Number(row.tax_amount),
    0
  );
  const totalTip = orderWiseViewRaw.reduce(
    (total, row) => total + Number(row.tip_amount),
    0
  );

  orderWiseViewRaw.push({
    sr_no: "Total",
    amount: totalAmount.toFixed(2),
    tax_amount: totalTax.toFixed(2),
    tip_amount: totalTip.toFixed(2),
    payment_type: "-",
    external_reference: "-",
    reason: "-",
    created_at: "-",
  });
  const itemWiseViewColumns = [
    {
      columnNames: "Sr No",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Product Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Quantity",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Amount",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Tax Amount	",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Payment Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "External Reference",
      sortName: "",
      width: "",
    },

    {
      columnNames: "Reason",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Refund Date",
      sortName: "",
      width: "",
    },
  ];
  //   let counter = 1;
  const itemWiseViewRaw = itemWiseData.map((data) => ({
    id: counter++,
    product_name: data.product_name,
    quantity: data.quantity,
    amount: data.amount,
    tax_amount: data.tax_amount,
    payment_type: data.payment_type == "STR_CR" ? "STORE CREDIT" : data.payment_type,
    external_reference: data.external_reference,
    reason: data.reason,
    // created_at: data.created_at,
    created_at: onlyDateFormate(data.created_at),
  }));
  const totalItemAmount = itemWiseViewRaw.reduce(
    (total, row) => total + Number(row.amount),
    0
  );
  const totalItemTax = itemWiseViewRaw.reduce(
    (total, row) => total + Number(row.tax_amount),
    0
  );
  itemWiseViewRaw.push({
    sr_no: "Total",
    product_name: "-",
    quantity: "-",
    amount: totalItemAmount.toFixed(2),
    tax_amount: totalItemTax.toFixed(2),
    payment_type: "-",
    external_reference: "-",
    reason: "-",
    created_at: "-",
  });
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Modal
        size={"6xl"}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Refund Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {orderRefundData.length > 0 && (
              <>
                {/* <Text>Order Refund</Text> */}
                <PosListHeader header={"Order Refund"} />
                <Box maxHeight="18rem" overflowY="auto">
                  <PosTable
                    columnNames={orderWiseViewColumns}
                    rawdata={orderWiseViewRaw}
                    totalcount={orderRefundData.length}
                    footerHide={true}
                    noAction={true}
                    sortColumnName={null}
                    page={null}
                    rowsPerPage={null}
                    order={null}
                  />
                </Box>
              </>
            )}
            {itemWiseData.length > 0 && (
              <>
                <Box mt={4} maxHeight="18rem" overflowY="auto">
                  <PosListHeader header={"Item Wise Refund"} />
                  <PosTable
                    columnNames={itemWiseViewColumns}
                    rawdata={itemWiseViewRaw}
                    totalcount={orderRefundData.length}
                    footerHide={true}
                    noAction={true}
                    sortColumnName={null}
                    page={null}
                    rowsPerPage={null}
                    order={null}
                  />
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Refund With Orders"]}
          breadCrumTitle={"Refund With Orders"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
         
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isMeduim ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              buttonText={"Reset"}
              isDisabled={applyClicked ? false : true}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.selectedLoactions ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable
              }
              onClick={getRefundOrderData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.REFUND_WITH_ORDER_CSV}
              />
              {/* <PosDataGridTable
                    columns={totalColumnName}
                    rows={totalDailySales}
                    totalcount={totalcount}
                    rowId={"totalDailyslesData"}
                    hideFooter={true}
                  /> */}
            </Flex>
          ) : (
            <PosNoDataFound title={"There are no Refund With Orders."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(RefundWithOrder);
